import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, Box, Grid } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import Preloader from '../../components/Preloader';
import Chart from '../../components/Chart/Chart';
import SidePanel from '../../components/SidePanel';
import FullChartModal from '../../components/Chart/FullChartModal';

import './LocationLayout.css';
import '../../index.css';
import 'react-toastify/dist/ReactToastify.css';
import { setTimerEnabled } from '../../redux/slices/feature';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse'
    },
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row'
    }
  },
  wrapper: {
    [theme.breakpoints.down('sm')]: {
      padding: 0
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2)
    }
  },
  leftPanel: {
    padding: '0px !important'
  }
}));

const LocationLayout = ({ data, match, setStatus }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);
  const [autoRefreshEnabled, setAutoRefreshEnabled] = useState(true);
  const [rightPanelExpanded, setRightPanelExpanded] = useState(true);

  const toggleRightPanelExpanded = () => setRightPanelExpanded(!rightPanelExpanded);

  const toggleAutoRefreshEnabled = (val) => {
    setAutoRefreshEnabled(!autoRefreshEnabled);
  };

  const onClickZoomIn = (key) => {
    setSelected(key);
  };

  const onCloseFullChart = () => {
    setSelected(null);
  };

  useEffect(() => {
    dispatch(setTimerEnabled(autoRefreshEnabled));
  }, [autoRefreshEnabled]);

  if (!data?.graph) {
    return <Preloader style={{ marginTop: 300 }} />;
  }

  const { graph, panel } = data;
  const showRightPanel = !!panel?.length;

  const graphList = Array.isArray(graph) ? graph : [graph];

  return (
    <Box className={classes.wrapper}>
      <Grid className={classes.container} container spacing={4}>
        <Grid
          className={classes.leftPanel}
          item
          lg={showRightPanel && rightPanelExpanded ? 8 : 12}
          xs={12}
        >
          <Box display="flex" width="100%" height="100%">
            <Box flex={1} p={2} overflow="hidden">
              {graphList.map((item, index) => (
                <Grid container key={item.id || index}>
                  <Chart
                    key={item.id || index}
                    id={item.id || index}
                    chartData={item}
                    size="large"
                    onClickZoomIn={onClickZoomIn}
                    width={12}
                    toggleAutoRefresh={toggleAutoRefreshEnabled}
                    autoRefresh={autoRefreshEnabled}
                  />
                </Grid>
              ))}
            </Box>
            {showRightPanel && (
              <Box
                display="flex"
                alignItems={!rightPanelExpanded ? 'flex-start' : 'center'}
                borderLeft="1px solid #eee"
                height="100%"
              >
                <Box
                  width={24}
                  height="100%"
                  display="flex"
                  alignItems="center"
                  style={{ cursor: 'pointer' }}
                  onClick={toggleRightPanelExpanded}
                >
                  {rightPanelExpanded ? <ChevronRight /> : <ChevronLeft />}
                </Box>
              </Box>
            )}
          </Box>
        </Grid>
        {showRightPanel && rightPanelExpanded && (
          <Grid item lg={4} xs={12}>
            <SidePanel data={panel} />
          </Grid>
        )}
      </Grid>

      <FullChartModal
        open={selected > -1 && selected !== null}
        onClose={onCloseFullChart}
        data={graphList[selected]}
      />
    </Box>
  );
};

export default LocationLayout;
