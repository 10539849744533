import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { makeStyles, Box } from '@material-ui/core';
import SummarySection from './SummarySection';
import { formatObjectArray, getToken } from '../../lib/util';
import { getSummary } from '../../lib/api';
import { selectAwsInfo } from '../../redux/slices/aws';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    width: '30%',
    minWidth: 480,
    height: '100%',
    top: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto',
      top: 'auto',
      left: 0
    }
  },
  container: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    paddingRight: theme.spacing(2.5),

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      alignItems: 'flex-end',
      width: '100%',
      height: '100%',
      paddingRight: 0
    }
  }
}));

const Summary = ({ showPopup, features = [] }) => {
  const classes = useStyles();
  const { product } = useParams();

  const awsInfo = useSelector(selectAwsInfo);

  const token = getToken();
  const [summaryList, setSummaryList] = useState({});

  const fetchData = async () => {
    try {
      const promises = features.map(async (feature) => {
        const res = await getSummary(feature.properties.Name, token);

        if (res.status && res.status !== 200) {
          return null;
        }

        return {
          metadata: feature.properties,
          data: res
        };
      });
      const data = await Promise.all(promises);
      setSummaryList(data.filter((d) => !!d));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (token && product) {
      fetchData();
    }
  }, [token, product, features]);

  if (!summaryList?.length) {
    return null;
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        {formatObjectArray(summaryList).map((item, idx) => (
          <SummarySection
            key={item.id || idx}
            {...item}
            showPopup={showPopup}
            awsInfo={awsInfo}
            token={token}
          />
        ))}
      </Box>
    </Box>
  );
};

export default Summary;
