import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Divider
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: 0,
    marginRight: 16
  }
}));

const Confirmation = ({
  open,
  title = 'Confirmation',
  text,
  okLabel = 'Yes',
  cancelLabel = 'No',
  onOK,
  onCancel,
  size = 'lg'
}) => {
  const classes = useStyles();

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      onClose={onCancel}
      aria-labelledby="edit-items-modal"
      maxWidth={size}
      fullWidth
    >
      <DialogContent>
        <Box py={2}>
          <Typography variant="h5">{title}</Typography>
        </Box>
        <Divider className={classes.divider} />
        <Box py={2}>
          <Typography variant="body1">{text}</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box pb={2}>
          <Button
            className={classes.button}
            variant="outlined"
            color="primary"
            onClick={onOK || onCancel}
          >
            {okLabel}
          </Button>
          <Button
            className={classes.button}
            variant="outlined"
            color="secondary"
            onClick={onCancel}
          >
            {cancelLabel}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default Confirmation;
