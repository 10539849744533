/* eslint-disable camelcase */
import { Box, Grid, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import React, { useState, memo, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import FullChartModal from '../../components/Chart/FullChartModal';
import Chart from '../../components/Chart/Chart';
import SidePanel from '../../components/SidePanel';
import { setTimerEnabled } from '../../redux/slices/feature';

const useStyles = makeStyles((theme) => ({
  responsiveBox: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0)
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2)
    }
  },
  rightPanelContainer: {
    padding: 20
  },
  leftPanel: {
    padding: '0px !important'
  }
}));

const MonitoringPage = memo(({ data }) => {
  const [selected, setSelected] = useState(null);
  const [autoRefreshEnabled, setAutoRefreshEnabled] = useState(true);
  const [rightPanelExpanded, setRightPanelExpanded] = useState(true);

  const toggleRightPanelExpanded = () => setRightPanelExpanded(!rightPanelExpanded);
  const toggleAutoRefreshEnabled = () => setAutoRefreshEnabled(!autoRefreshEnabled);

  const classes = useStyles();
  const dispatch = useDispatch();

  const onClickZoomIn = (key) => {
    setSelected(key);
  };

  const onCloseFullChart = () => {
    setSelected(null);
  };

  useEffect(() => {
    dispatch(setTimerEnabled(autoRefreshEnabled));
  }, [autoRefreshEnabled]);

  if (!data || !data.graph) {
    return null;
  }

  const { panel, graph } = data;
  const graphList = Array.isArray(graph) ? graph : [graph];
  const rightPanelExisting = panel && panel.length;

  return (
    <Box>
      <Box className={classes.responsiveBox}>
        <Grid container spacing={4}>
          <Grid
            className={classes.leftPanel}
            item
            lg={rightPanelExisting && rightPanelExpanded ? 8 : 12}
            xs={12}
          >
            <Box display="flex" width="100%" height="100%">
              <Box flex={1} p={2} overflow="hidden">
                <Grid container>
                  {graphList.map((value, key) => (
                    <Chart
                      key={value.id || key}
                      id={value.id || key}
                      chartData={value}
                      onClickZoomIn={onClickZoomIn}
                      toggleAutoRefresh={toggleAutoRefreshEnabled}
                      autoRefresh={autoRefreshEnabled}
                    />
                  ))}
                </Grid>
              </Box>
              {rightPanelExisting && (
                <Box
                  display="flex"
                  alignItems={!rightPanelExpanded ? 'flex-start' : 'center'}
                  borderLeft="1px solid #eee"
                  height="100%"
                >
                  <Box
                    width={24}
                    height="100%"
                    display="flex"
                    alignItems="center"
                    style={{ cursor: 'pointer' }}
                    onClick={toggleRightPanelExpanded}
                  >
                    {rightPanelExpanded ? <ChevronRight /> : <ChevronLeft />}
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>

          {rightPanelExisting && rightPanelExpanded && (
            <Grid item md={12} xs={12} lg={4}>
              <SidePanel data={panel} />
            </Grid>
          )}
        </Grid>
      </Box>

      <FullChartModal
        open={selected > -1 && selected !== null}
        onClose={onCloseFullChart}
        data={graph[selected]}
      />
    </Box>
  );
});

export default MonitoringPage;
