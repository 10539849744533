/* eslint-disable react/no-array-index-key */
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Panel, Table } from 'react-bootstrap';
import Preloader from '../Preloader';
import { formatObjectArray } from '../../lib/util';

const useStyles = makeStyles((theme) => ({
  img: {
    height: '300px',
    marginRight: '0px',
    marginLeft: '0px'
  },
  somePageWrapper: {
    display: 'flex',
    margin: '0px'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%'
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%',
    flex: 1
  },
  table: {
    maxWidth: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginBottom: '0px'
  }
}));

const CurrentStatusPopup = ({ summary }) => {
  const classes = useStyles();

  if (summary) {
    return (
      <Panel>
        <Panel.Body>
          <div className={classes.somePageWrapper}>
            <div className={classes.row}>
              <div className={classes.column}>
                <div>
                  <Table className={classes.table} responsive>
                    <tbody>
                      {formatObjectArray(summary).map((item, index) => (
                        <tr key={index}>
                          <th key={(index + 1) * 100}>{item.name}</th>
                          <td key={(index + 1) * 1000}>{item.description}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </Panel.Body>
      </Panel>
    );
  } else if (summary) {
    // eslint-disable-next-line react/jsx-fragments
    return <React.Fragment />;
  } else {
    return <Preloader />;
  }
};

export default CurrentStatusPopup;
