import React from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import CapacityForecastCell from './CapacityForecastCell';

const useStyles = makeStyles({
  tableContainer: {
    maxWidth: '100vw',
    height: '100%'
  },
  table: {
    width: '100%'
  },
  forecastTime: {
    // transformOrigin: 'center',
    // transform: 'rotate(-90deg)'
  },
  forecastTimeRow: {},
  forecastTimeCell: {
    writingMode: 'vertical-rl',
    textOrientation: 'mixed',
    margin: 'auto',
    maxWidth: 20
  },
  borderCell: {
    // borderRight: '2px solid black'
    maxWidth: 40,
    padding: 0
  }
});

const formatTime = (time) => moment(time * 1000).format('DD/MM h:mm');

const CapacityForecastTable = ({ data }) => {
  // const { fc_value: value, fc_time: time, fc_time_issue: timeIssue, fc_value_max: maxValue } = data;
  const {
    series: { data: value, data_max: dataMax },
    axis: { column, row }
  } = data;

  const rows = value.map((item, index) => [formatTime(row[index]), ...item]);
  const forecastTimes = ['', ...column.map((t) => formatTime(t))];
  const classes = useStyles();

  return (
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={row.id || index} className={classes.forecastTimeRow}>
              {index === 0 && (
                <TableCell className={classes.borderCell} rowSpan={rows.length}>
                  <Typography className={classes.forecastTimeCell}>Forecast Issue Time</Typography>
                </TableCell>
              )}
              {row.map((item, i) => (
                <CapacityForecastCell
                  key={item?.id || index * rows.length + i}
                  value={item}
                  maxValue={dataMax}
                />
              ))}
            </TableRow>
          ))}
          <TableRow key="forecast-times-row">
            <TableCell colSpan={1} />
            {forecastTimes.map((t, index) => (
              <TableCell key={t.id || index} className={classes.forecastTime} align="right">
                <div className={classes.forecastTimeCell}>{t}</div>
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell colSpan={2} />
            <TableCell colSpan={rows.length} align="center">
              Forecast Time
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CapacityForecastTable;
