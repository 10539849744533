/* eslint-disable no-nested-ternary */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Tooltip as Tooltips,
} from '@material-ui/core';
import InfoIcon from '@mui/icons-material/Info';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import CheckIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/Warning';
import { formatObjectArray, getToken } from '../lib/util';

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid gray'
  },
  container: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    height: 'auto',
    marginTop: 0
  },
  wrapper: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.5)
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4)
    }
  },
  title: {
    paddingTop: 0,
    paddingBottom: '.5rem',
    marginRight: '10px',

    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(1)
    }
  },
  rightItem: {
    textAlign: 'right'
  },
  img: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%'
  },
  downloadWrapper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  section: {
    width: '100%',
    paddingTop: theme.spacing(2),

    '&:last-child': {
      borderBottom: 'none',
      marginBottom: 0
    },

    '& .thumbs-wrapper': {
      display: 'none'
    }
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  titleIcon: {
    marginLeft: 20
  },
  listItemIcon: {
    minWidth: 0
  },
  listItemIconStatic: {
    minWidth: theme.spacing(5)
  },
  listItemText: {
    flex: 1
  },
  listItemValue: {
    flex: 0,
    paddingRight: theme.spacing(2)
  },
  neutralIcon: {
    color: '#ffe100',
    background: '#fff'
  },
  availableIcon: {
    color: 'green'
  },
  unavailableIcon: {
    color: 'red'
  },
  tooltip: {
    backgroundColor: "lightGrey",
    color: "black",
    fontSize: "15px"
  },
  toolTipDiv: {
    display: "flex"
  }
}));

const getStatusIcon = (value, classes) => {
  if (value > 0) {
    return <CheckIcon className={classes.availableIcon} />;
  }

  if (value < 0) {
    return <WarningIcon className={classes.neutralIcon} />;
  }

  return null;
};

const SidePanel = ({ data, toggleExpand, isExpanded = true }) => {
  const classes = useStyles();
  const token = getToken();

  const carouselProps = {
    className: 'owl-theme',
    rewind: true,
    loop: true,
    margin: 0,
    nav: true,
    items: 1,
    stagePadding: 0,
    dotClass: 'hide',
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    showIndicators: false,
    showStatus: false
  };

  return (
    <Box
      display="flex"
      alignItems={!toggleExpand ? 'flex-start' : 'center'}
      borderLeft="1px solid #eee"
      height="100%"
    >
      <Grid className={classes.container} container>
        {data.map(({ type, title, fields, id, tooltip }, index) => {
          if (type === 'video') {
            return (
              <Grid key={id || index} item className={classes.section} xs={12} md={6} lg={12}>
                <Box className={classes.wrapper}>
                  <div className={classes.toolTipDiv}>
                    <Typography className={classes.title} variant="h5">
                      {title}
                    </Typography>
                    {tooltip && <>
                                  <Tooltips  classes={{ tooltip: classes.tooltip }} title={tooltip}>
                                    <InfoIcon color="primary"/>
                                  </Tooltips>
                                </>
                    } 
                  </div>
                  <Divider variant="fullWidth" />

                  <Box py={2}>
                    <Carousel {...carouselProps}>
                      {(Array.isArray(fields) ? fields : [fields]).map(
                        ({ id, lapse, name, preview }) => (
                          <div key={id} className={classes.img}>
                            <video
                              width="100%"
                              height="100%"
                              display="block"
                              controls
                              autoPlay
                              muted
                              src={`${process.env.REACT_APP_API_URL}/GetImage/${token}/${lapse}`}
                            />
                          </div>
                        )
                      )}
                    </Carousel>
                  </Box>
                </Box>
              </Grid>
            );
          }

          if (type === 'table') {
            const hasIcon = fields.some((f) => f.Warning !== 0);
            const iconClasses = hasIcon ? classes.listItemIconStatic : classes.listItemIcon;
            return (
              <Box className={[classes.section, classes.wrapper]} key={id || index}>
                <div className={classes.toolTipDiv}>
                  <Typography className={classes.title} variant="h5">
                    {title}
                  </Typography>
                  {tooltip && <>
                                <Tooltips  classes={{ tooltip: classes.tooltip }} title={tooltip}>
                                  <InfoIcon color="primary"/>
                                </Tooltips>
                              </>
                  } 
                </div>
                <Divider variant="fullWidth" />
                <List>
                  {formatObjectArray(fields).map(
                    ({ id, Label: label, Warning: warning, Value: value }) => (
                      <ListItem key={id}>
                        <ListItemIcon className={iconClasses}>
                          {getStatusIcon(warning, classes)}
                        </ListItemIcon>
                        <ListItemText className={classes.listItemText}>{label}</ListItemText>
                        <ListItemText className={classes.listItemValue}>{value}</ListItemText>
                      </ListItem>
                    )
                  )}
                </List>
              </Box>
            );
          }

          return null;
        })}
      </Grid>
    </Box>
  );
};

export default SidePanel;
