/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  username: null,
  token: null
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthInfo: (state, action) => {
      state.username = action.payload.username;
      state.token = action.payload.token;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    clearToken: (state) => {
      state.token = null;
    }
  }
});

export const { setToken, clearToken, setAuthInfo } = authSlice.actions;
export const selectToken = (state) => state.auth.token;
export const selectUsername = (state) => state.auth.username;

export default authSlice.reducer;
