/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    flexGrow: 1,
    flexWrap: 'nowrap',
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto'
  },
  tabs: {
    backgroundColor: theme.palette.background.default,

    '& .MuiTab-wrapper': {
      [theme.breakpoints.down('sm')]: {
        fontSize: 12
      }
    }
  },
  tabPanel: {
    flex: 1,
    overflow: 'auto',

    '& > div': {
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
        paddingBottom: theme.spacing(3)
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(2.5)
      }
    }
  }
}));
