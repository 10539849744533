import { Button, Menu, MenuItem } from '@material-ui/core';
import React, { useState } from 'react';

const ProaMenu = ({ items, label }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button onClick={handleClick}>{label}</Button>
      <Menu
        id="proa-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {items.map(({ id, label, onClick }, index) => (
          <MenuItem
            key={id || index}
            onClick={() => {
              if (onClick) {
                onClick();
                handleClose();
              }
            }}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default ProaMenu;
