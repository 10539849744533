/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { makeStyles, Grid, Accordion, AccordionSummary, AccordionDetails, Tooltip as Tooltips, Button } from '@material-ui/core';
import InfoIcon from '@mui/icons-material/Info';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Panel, Table } from 'react-bootstrap';
import Preloader from '../Preloader';
import { formatObjectArray } from '../../lib/util';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1,
    '&.MuiAccordion-root': {
      display: 'flex',
      flexDirection: 'column-reverse',
      marginBottom: 0
    }
  },
  panel: {
    width: '100%',
    border: 'none'
  },
  panelBody: {
    padding: 0,
    marginBottom: "10px",
  },
  detailsContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(0)
  },
  header: {
    '&.MuiAccordionSummary-root': {
      borderBottom: 'none',
      minHeight: theme.spacing(4)
    },

    '& .MuiAccordionSummary-content': {
      display: 'none'
    },
    '& .MuiAccordionSummary-expandIcon': {
      padding: 0
    }
  },
  expandBtn: {
    padding: 0
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%',
    flex: 1,

    [theme.breakpoints.down('sm')]: {
      fontSize: 12
    }
  },
  img: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    maxWidth: 300
  },
  somePageWrapper: {
    display: 'flex',
    marginBottom: "10px",
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%'
  },
  tableHeader: {
    width: 240,

    [theme.breakpoints.down('sm')]: {
      width: '40%'
    }
  },
  tooltip: {
    backgroundColor: "lightGrey",
    color: "black",
    fontSize: "15px"
  }
}));

const CurrentStatus = ({ summary, tooltip }) => {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(true);

  const toggleExpanded = () => setExpanded(!expanded);

  if (summary) {
    return (
      <>
        <Helmet>
          <title>Proa | {summary[0].description}</title>
        </Helmet>
        <Accordion className={classes.root} expanded={expanded} onChange={toggleExpanded}>
          <AccordionSummary
            className={classes.header}
            expandIcon={<ExpandMoreIcon className={classes.expandBtn} />}
          />
          <AccordionDetails className={classes.detailsContainer}>
            <Panel className={classes.panel}>
              <Panel.Body className={classes.panelBody}>
                <div className={classes.somePageWrapper}>
                  <div className={classes.row}>
                    <Grid container className={classes.column}>
                      <Grid item xs={12} md={12} lg={12}>
                        <Table
                          style={{
                            maxWidth: '100%',
                            marginRight: 'auto',
                            marginLeft: 'auto',
                            marginBottom: 0,
                            marginTop: 0
                          }}
                          responsive
                          bordered
                        >
                          <tbody>
                            {formatObjectArray(summary).map((item, index) => (
                              <tr key={index}>
                                <th key={(index + 1) * 100} className={classes.tableHeader}>
                                  {item.name}
                                </th>
                                <td key={(index + 1) * 1000}>{item.description}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                {/* {
                  tooltip && <>
                              <Tooltips classes={{ tooltip: classes.tooltip }} title={tooltip}>
                                <Button variant="contained" mt={2}>Learn More<InfoIcon color="primary"/></Button>
                              </Tooltips>  
                            </>
                } */}
              </Panel.Body>
            </Panel>
          </AccordionDetails>
        </Accordion>
      </>
    );
  } else {
    return <Preloader />;
  }
};

export default CurrentStatus;
