/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useMemo } from 'react';
import { Box } from '@material-ui/core';
import Filters from './Filters';
import AwsDataGraph from './AwsDataGraph';
import AwsDataTable from './AwsDataTable';
import FullScreenModal from '../../components/modals/FullScreenModal';
import Preloader from '../../components/Preloader';
import { buildAwsData } from '../../lib/util';

const AwsDataSection = ({
  number,
  tabName,
  variableOptions,
  product,
  awsId,
  interval,
  fetchAwsData,
  downloadCsv
}) => {
  const [awsData, setAwsData] = useState();
  const [graph, setGraph] = useState(null);
  const [hidden, setHidden] = useState(false);
  const [format, setFormat] = useState('table'); // 'table', 'graph' or 'both'
  const [start, setStart] = useState(new Date() - 3600 * 24 * 7 * 1000);
  const [end, setEnd] = useState(new Date());
  const [graphSize, setGraphSize] = useState({ width: 0, height: 0 });
  const [layout, setLayout] = useState('flex');
  const [loading, setLoading] = useState(false);

  const onCloseFullScreen = () => setGraph(null);

  const processedData = useMemo(() => buildAwsData(awsData), [awsData]);

  const handleDownloadCsv = () => downloadCsv(processedData);

  const handleDownloadAllInCsv = async () => {
    try {
      const data = await fetchAwsData(
        variableOptions.map((v) => v.Name),
        start,
        end,
        awsId
      );

      if (data?.length) {
        downloadCsv(buildAwsData(data));
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!graphSize?.width) return;

    if (graphSize?.width < 600 && layout === 'flex') {
      setLayout('block');
    } else if (graphSize?.width > 1440 && layout === 'block') {
      setLayout('flex');
    }
  }, [graphSize?.width, layout]);

  return (
    <>
      {hidden ? null : (
        <>
          <Box pt={4} fontSize="1.25rem">
            Section {number}
          </Box>
          <Filters
            tabName={tabName}
            variableOptions={variableOptions}
            setAwsData={setAwsData}
            setLoading={setLoading}
            product={product}
            awsId={awsId}
            format={format}
            setFormat={setFormat}
            fetchAwsData={fetchAwsData}
            start={start}
            end={end}
            setStart={setStart}
            setEnd={setEnd}
          />
          <Box display={layout} width="100%" pt={4}>
            {loading ? (
              <Preloader />
            ) : (
              <>
                {format === 'graph' || format === 'both' ? (
                  <Box
                    pr={format === 'both' ? 1 : 0}
                    pb={layout === 'block' ? 2 : 0}
                    flex={1}
                    overflow="hidden"
                  >
                    <AwsDataGraph
                      data={processedData}
                      downloadCsv={handleDownloadCsv}
                      downloadAllInCsv={handleDownloadAllInCsv}
                      tabName={tabName}
                      product={product}
                      hidden={hidden}
                      interval={interval}
                      variableOptions={variableOptions}
                      setGraph={setGraph}
                      setHidden={setHidden}
                      setFormat={setFormat}
                      setGraphSize={setGraphSize}
                    />
                    <FullScreenModal
                      open={!!graph}
                      headerVisible={false}
                      handleClose={onCloseFullScreen}
                    >
                      <AwsDataGraph
                        data={processedData}
                        downloadCsv={handleDownloadCsv}
                        downloadAllInCsv={handleDownloadAllInCsv}
                        tabName={tabName}
                        product={product}
                        interval={interval}
                        variableOptions={variableOptions}
                        onCloseFullScreen={onCloseFullScreen}
                      />
                    </FullScreenModal>
                  </Box>
                ) : null}
                {format === 'table' || format === 'both' ? (
                  <Box
                    flex={1}
                    overflow="hidden"
                    pl={format === 'both' ? 1 : 0}
                    pb={layout === 'block' ? 2 : 0}
                  >
                    <AwsDataTable
                      data={processedData}
                      variableOptions={variableOptions}
                      setFormat={setFormat}
                      downloadCsv={processedData.length ? handleDownloadCsv : null}
                      downloadAllInCsv={handleDownloadAllInCsv}
                      setHidden={setHidden}
                      height={layout === 'flex' ? graphSize.height : 0}
                    />
                  </Box>
                ) : null}
              </>
            )}
          </Box>
        </>
      )}
    </>
  );
};

export default AwsDataSection;
