/* eslint-disable no-nested-ternary */
import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  tableCell: ({ value, maxValue }) => ({
    maxWidth: 50,
    padding: 4,
    border: 'none',
    backgroundColor:
      typeof value === 'number' && value >= 0
        ? `rgb(${255 - ((value / maxValue) * 70 - 10) * 4}, 255, ${
            175 - (value / maxValue) * 70 * 2.5
          })`
        : typeof value === 'string'
        ? '#fff'
        : '#f0f0f0'
  })
});

const CapacityForecastCell = ({ value, maxValue }) => {
  const classes = useStyles({ value, maxValue });
  return (
    <TableCell className={classes.tableCell} align="center">
      {value?.toFixed ? value.toFixed(2) : value}
    </TableCell>
  );
};

export default CapacityForecastCell;
