import React, { useState } from 'react';
import moment from 'moment-timezone';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
  Typography
} from '@material-ui/core';
import EditItem from './EditItem';
import ConfirmationModal from '../../components/modals/Confirmation';

const useStyles = makeStyles((theme) => ({
  dialog: {
    // width: 720
  },
  modalbody: {
    width: 1200,
    backgroundColor: theme.palette.background.paper
  },
  formControl: {
    marginLeft: 16
  },
  divider: {
    marginTop: 16,
    marginBottom: 16
  },
  button: {
    minWidth: 100,
    margin: "5px",

  }
}));

const EditItemsModal = ({
  compId,
  compData,
  open,
  items,
  feature,
  handleClose,
  onAddNewItem,
  onCancelItem
}) => {
  const classes = useStyles();

  const [itemToAdd, setItemToAdd] = useState(null);
  const [itemToCancel, setItemToCancel] = useState(null);

  const onClickAdd = (id) => {
    setItemToAdd(id);
  };

  const onClickCancel = (id) => {
    setItemToCancel(id);
  };

  const closeConfirmCancel = () => {
    setItemToCancel(null);
  };

  const onConfirmNewItem = ({ id, newAvailability, newDate }) => {
    onAddNewItem({ id, date: newDate, availability: newAvailability });
    setItemToAdd(null);
  };

  const onConfirmCancel = () => {
    onCancelItem({ id: itemToCancel });
    setItemToCancel(null);
  };

  const selectedItem = items.find((i) => i.id === itemToAdd);

  return (
    <>
      <Dialog
        className={classes.dialog}
        open={open}
        onClose={handleClose}
        aria-labelledby="edit-items-modal"
        maxWidth="lg"
      >
        <DialogContent>
          <Typography variant="h5">{compData?.title}</Typography>
          <Divider className={classes.divider} />

          <TableContainer>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Component</TableCell>
                  <TableCell align="right">Current Availability</TableCell>
                  <TableCell align="right">Planned outage start</TableCell>
                  <TableCell align="right">Planned outage availability</TableCell>
                  <TableCell align="right">Add/Cancel Planned outage</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map(
                  ({
                    id,
                    name,
                    currentAvailability,
                    plannedOutageStart,
                    plannedOutageAvailability
                  }) => (
                    <TableRow key={id}>
                      <TableCell align="center">{name}</TableCell>
                      <TableCell align="center">{currentAvailability}%</TableCell>
                      <TableCell align="center">
                        {plannedOutageStart
                          ? moment(plannedOutageStart).format('DD/MM/yy HH:mm Z')
                          : ''}
                      </TableCell>
                      <TableCell align="center">
                      {
                        (typeof plannedOutageAvailability === 'number' || typeof plannedOutageAvailability === 'string')
                          ? `${plannedOutageAvailability}%`
                          : ''
                      }
                      </TableCell>
                      <TableCell align="center">
                        {plannedOutageStart ? (
                          <>
                            <Button
                              className={classes.button}
                              variant="contained"
                              color="primary"
                              onClick={() => onClickAdd(id)}
                            >
                              Edit
                            </Button>
                            <Button
                              className={classes.button}
                              variant="contained"
                              color="secondary"
                              onClick={() => onClickCancel(id)}
                            >
                              Cancel
                            </Button>
                          </>
                        ) : (
                          <Button
                            className={classes.button}
                            variant="contained"
                            color="primary"
                            onClick={() => onClickAdd(id)}
                          >
                            Add
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Divider />
        </DialogContent>
        <DialogActions>
          <Box p={2}>
            <Button onClick={handleClose}>CLOSE</Button>
          </Box>
        </DialogActions>
      </Dialog>
      {itemToAdd && (
        <EditItem
          open={!!itemToAdd}
          handleSave={onConfirmNewItem}
          handleClose={() => setItemToAdd(null)}
          feature={feature}
          {...selectedItem}
        />
      )}

      <ConfirmationModal
        open={!!itemToCancel}
        text="Are you sure you want to cancel this planned outage?"
        onOK={onConfirmCancel}
        onCancel={closeConfirmCancel}
      />
    </>
  );
};

export default EditItemsModal;
