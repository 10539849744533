/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  location: null
};

export const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setLocation: (state, action) => {
      state.location = action.payload;
    },
    clearLocation: (state) => {
      state.location = null;
    }
  }
});

export const { setLocation, clearLocation } = locationSlice.actions;
export const selectLocation = (state) => state.location.location;

export default locationSlice.reducer;
