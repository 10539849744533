/* eslint-disable no-return-await */
/* eslint-disable no-use-before-define */
import axios from 'axios';
import { getUser, getPassword } from './util';

const { REACT_APP_API_URL } = process.env;

const getAPIEndpoint = (url) => `${REACT_APP_API_URL}${url}`;

const getReportFileLink = (token, product, fileType, fileName) =>
  `${REACT_APP_API_URL}/GetReportFile/${token}/${product}/${fileType}/${fileName}`;

const getPopupData = async (name, token) => {
  const path = `/PopupData/${token}/${name}`;
  const requestData = await get(path, false);
  return { ...requestData, metadata: { ...requestData.metadata, product: name, token } };
};

const getValidUserData = async (name, token) => {
  const path = `/AuthUser/${token}/${name}`;
  const requestData = await get(path, false);
  return requestData;
};

const getSignInData = async (username, password) => {
  const path = `/SignIn`;
  const requestData = await post(path, false, { username, password });
  return requestData;
};

const getForecastData = async (name, token) => {
  const path = `/ForecastData/${token}/${name}`;
  const requestData = await get(path, false);
  return requestData;
};

const getPerformanceMPF5Data = async (name, token) => {
  const path = `/PerformanceMPF5Data/${token}/${name}`;
  const requestData = await get(path, false);
  return requestData;
};

const getHistoryData = async (name, token) => {
  const path = `/HistoryData/${token}/${name}`;
  const requestData = await get(path, false);
  return requestData;
};

const getAvailabilityData = async (name, token) => {
  const path = `/GetAvailability/${token}/${name}`;
  const availability = await get(`${path}/availability`, false);
  const outage = await get(`${path}/outage`, false);
  return {
    availability,
    outage
  };
};

const postAvailabilityData = async (name, token, data) => {
  const path = `/PostAvailability/${token}/${name}`;
  const requestData = await post(path, false, data);
  return requestData;
};

const getReportsList = async (name, token) => {
  const path = `/GetReportsList/${token}/${name}`;
  const requestData = await get(path, false);
  return requestData;
};

const getMonitoring = async (name, token) => {
  const path = `/GetMonitoring/${token}/${name}`;
  const requestData = await get(path, false);
  return requestData;
};

const getWeather = async (name, token) => {
  const path = `/GetWeather/${token}/${name}`;
  const requestData = await get(path, false);
  return requestData;
};

const getMapPoints = async (name, token) => {
  const path = `/MapPoints/${token}/${name}`;
  const requestData = await get(path, false);
  return requestData;
};

const getSummary = async (name, token) => {
  const path = `/GetSummary/${token}/${name}`;
  const requestData = await get(path, false);
  return requestData;
};

const getAwsData = async (name, token, awsId, dataTable, start, end, variables) => {
  const path = `/GetAwsData/${token}/${name}/${awsId}/${dataTable}?start=${start}&end=${end}&variables=${variables}`;
  const requestData = await get(path, false);
  return requestData;
};

const getAwsTableInfo = async (name, token) => {
  const path = `/GetAwsTableInfo/${token}/${name}`;
  const requestData = await get(path, false);
  return requestData;
};

const getAwsMetadata = async (name, token) => {
  const path = `/GetAwsMetadata/${token}/${name}`;
  const requestData = await get(path, false);
  return requestData;
};

const getAwsVariables = async (name, token, awsId, dataTable) => {
  const path = `/GetAwsVariables/${token}/${name}/${awsId}/${dataTable}`;
  const requestData = await get(path, false);
  return requestData;
};

const getSuppressInfo = async (name, token) => {
  const path = `/GetSuppressInfo//${token}/${name}`;
  const requestData = await get(path, false);
  return requestData;
};

const postSuppressInfo = async (name, token, data) => {
  const path = `/PostSuppress/${token}/${name}`;
  const requestData = await post(path, false, data);
  return requestData;
};

const getCustomerInfo = async (customerId, token) => {
  const path = `/GetCustomerInfo/${token}/${customerId}`;
  const requestData = await get(path, false);
  return requestData;
};

const get = (path, isAuth) => call(createRequest('GET', path, isAuth));
const post = (path, isAuth, data) => call(createRequest('POST', path, isAuth, data));
const createRequest = (method, path, isAuth, data) => {
  const param = {
    method,
    url: getAPIEndpoint(path),
    headers: {
      'Content-Type': 'application/json',
      Username: getUser(),
      Password: getPassword()
    }
  };
  if (data) {
    param.data = JSON.stringify(data);
  }
  return param;
};

const getFeatures = async (url) => await call(url);

const call = async (params) => {
  try {
    const response = await axios(params);
    return response.data;
  } catch (e) {
    return { status: 500, message: JSON.stringify(e) };
  }
};

export {
  getFeatures,
  getPopupData,
  getValidUserData,
  getSignInData,
  getForecastData,
  getAvailabilityData,
  postAvailabilityData,
  getReportsList,
  getReportFileLink,
  getMonitoring,
  getWeather,
  getMapPoints,
  getSummary,
  getHistoryData,
  getPerformanceMPF5Data,
  getAwsData,
  getAwsTableInfo,
  getAwsVariables,
  getAwsMetadata,
  getSuppressInfo,
  postSuppressInfo,
  getCustomerInfo
};
