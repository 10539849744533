import React, { useRef, useMemo } from 'react';
import moment from 'moment';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { Line } from 'react-chartjs-2';
import { Box, Button, Typography } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import ProaMenu from '../../components/menu';
import { downloadAsImg, findVariableLongName, findVariableUnit } from '../../lib/util';
import { useStyles } from './styles';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

const COLORS = [
  '#1f78b4',
  '#e31a1c',
  '#33a02c',
  '#a6cee3',
  '#fb9a99',
  '#b2df8a',
  '#fdbf6f',
  '#000000'
];

const MAX_DATA_POINTS = 2000;
const VALID_INTERVALS = [
  15,
  30,
  60,
  300,
  600,
  900,
  1200,
  1500,
  1800,
  2100,
  2400,
  2700,
  3000,
  3300,
  3600,
  24 * 3600
];
const VALID_STEP_SIZES = [5 / 60, 15 / 60, 30 / 60, 60 / 60];

const timeFormat = 'DD MMM, HH:mm:ss Z';

function AwsDataGraph({
  data,
  downloadCsv,
  downloadAllInCsv,
  tabName,
  product,
  hidden,
  variableOptions,
  interval = 300,
  setGraph,
  setHidden,
  onCloseFullScreen,
  setFormat,
  setGraphSize
}) {
  const classes = useStyles();
  const chartRef = useRef(null);

  const hideDisplay = () => setHidden(true);
  const resetZoom = () => {
    chartRef.current.resetZoom();
  };

  const downloadImg = () => {
    const imgData = chartRef.current.toBase64Image();
    downloadAsImg(imgData, `${product}-${tabName}`);
  };

  const allColumns = Object.keys(data[0] ?? {}).filter((c) => c !== 'timestamp');

  const units = allColumns.reduce((acc, current) => {
    const unit = variableOptions.find((v) => v.Name === current)?.Unit;
    if (unit && !acc.includes(unit) && acc.length < 3) {
      acc.push(unit);
    }
    return acc;
  }, []);

  const columns = allColumns.reduce((acc, current) => {
    const unit = variableOptions.find((v) => v.Name === current)?.Unit;
    if (units.includes(unit)) {
      acc.push(current);
    }

    return acc;
  }, []);

  const decimatedData = useMemo(() => {
    if (data.length <= MAX_DATA_POINTS) return data;
    const tempInterval = Math.ceil(data.length / MAX_DATA_POINTS) * interval;
    const index = VALID_INTERVALS.findIndex((vi) => vi > tempInterval) - 1;
    const indexInterval = VALID_INTERVALS[index] / interval;

    // Try to include minute points
    let firstItemIndex = data.findIndex((item) => item.timestamp % 60 === 0);
    if (firstItemIndex < 0) firstItemIndex = 0;

    return data.filter((_, index) => index % indexInterval === firstItemIndex % indexInterval);
  }, [data, interval]);

  const chartData = useMemo(
    () => ({
      labels: decimatedData.map((item) =>
        moment(new Date(item.timestamp * 1000)).format(timeFormat)
      ),
      datasets: columns.map((c, idx) => ({
        label: findVariableLongName(variableOptions, c),
        borderColor: COLORS[idx % COLORS.length],
        data: decimatedData.map((item) => item[c]),
        yAxisID: findVariableUnit(variableOptions, c)
        // parsing: false
      }))
    }),
    [decimatedData, columns, interval]
  );

  const scales = columns.reduce((acc, current, index) => {
    const unit = findVariableUnit(variableOptions, current);
    if (acc[unit]) return acc;
    acc[unit] = {
      type: 'linear',
      display: true,
      position: Object.keys(acc).length % 2 === 0 ? 'left' : 'right',
      title: {
        display: true,
        text: unit
      }
    };
    return acc;
  }, {});

  if (!data?.length) return null;

  const minTime = data[0].timestamp;
  const maxTime = data[data.length - 1].timestamp;
  const initialStepSize = (maxTime - minTime) / (60 * 60 * 6);
  const stepSize =
    VALID_STEP_SIZES.find((vs) => vs >= initialStepSize) || parseInt(initialStepSize, 10);

  scales.x = {
    type: 'time',
    min: moment(minTime * 1000).format(timeFormat),
    max: moment(maxTime * 1000).format(timeFormat),
    time: {
      unit: 'hour',
      stepSize,
      displayFormats: { hour: timeFormat },
      tooltipFormat: timeFormat
    }
    // display: true,
    // ticks: {
    //   fontSize: 10
    // }
  };

  const options = {
    animation: false,
    maintainAspectRatio: false,
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false
    },
    stacked: false,
    onResize: (chart, size) => {
      if (setGraphSize) {
        setGraphSize(size);
      }
    },
    plugins: {
      legend: {
        position: 'top'
      },
      title: {
        display: false
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'xy',
          modifierKey: 'shift'
        },
        zoom: {
          mode: 'xy',
          drag: {
            enabled: true,
            borderColor: 'rgb(54, 162, 235)',
            borderWidth: 1,
            backgroundColor: 'rgba(54, 162, 235, 0.3)'
          },
          onZoomComplete: ({ chart }) => {
            // setTimeout(() => {
            //   setResetZoomVisible(chart.getZoomLevel() > 1);
            // }, 100);
            // chart.update('none');
          }
        }
      }
    },
    scales
  };

  const menuItems = [
    {
      label: 'Download CSV',
      onClick: downloadCsv
    },
    {
      label: 'Download All Variables',
      onClick: downloadAllInCsv
    },
    {
      label: 'Download image',
      onClick: downloadImg
    }
  ];

  if (setGraph) {
    menuItems.unshift({
      label: 'View in full screen',
      onClick: () => setGraph(data)
    });
  }
  if (onCloseFullScreen) {
    menuItems.unshift({
      label: 'Exit full screen',
      onClick: onCloseFullScreen
    });
  }
  menuItems.push({
    label: 'Remove display',
    onClick: hideDisplay
  });

  if (hidden) return null;

  return (
    <Box height="100%">
      {data.length ? (
        <Box position="relative" height="100%" paddingTop={4}>
          {allColumns.length > columns.length && (
            <Box position="absolute" top={0} left={0}>
              <Typography color="error" variant="body2">
                Some variables are not displayed.
              </Typography>
            </Box>
          )}
          <div id="mini-graph" style={{ height: '50vh' }}>
            <Line ref={chartRef} options={options} data={chartData} height="100%" />
          </div>
          <Box position="absolute" top={0} right={0} display="flex" alignItems="center">
            <Button
              className={classes.resetBtn}
              size="small"
              variant="outlined"
              color="primary"
              onClick={resetZoom}
            >
              Reset zoom
            </Button>

            <ProaMenu label={<MenuIcon />} items={menuItems} />
          </Box>
        </Box>
      ) : (
        <Box p={2}>No data to render, please try another filter to load data.</Box>
      )}
    </Box>
  );
}

export default AwsDataGraph;
