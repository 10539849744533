import React from 'react';

const PrivacyPolicy = () => (
  <div style={{ padding: '40px', fontSize: '150%' }}>
    <h1>About</h1>
    <p>
      Proa Analytics is an Australian company of engineers and scientists. With a deep knowledge
      about solar, optimisation, and energy systems, we provide validated forecasting and modelling
      solutions to our customers.
    </p>
    <p>
      For more information, please feel free to{' '}
      <a href="https://proaanalytics.com/contact/">contact us</a>.
    </p>
  </div>
);

export default PrivacyPolicy;
