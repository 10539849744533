/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {},
  accordionContent: {
    padding: 16
  },
  accordionHeader: {
    backgroundColor: '#eee',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4
  },
  checkboxWrapper: {
    '&.MuiGrid-item': {
      paddingTop: 4,
      paddingBottom: 4
    }
  },
  tableCell: {
    borderRight: '1px solid #eee',

    '&:first-child': {
      borderLeft: '1px solid #eee'
    },

    '&.MuiTableCell-head': {
      fontWeight: 700,
      textTransform: 'capitalize'
    }
  },
  resetBtn: {
    fontSize: '0.75rem'
  },  
  tooltipDiv: {
    display: 'flex'
  },
  tooltip: {
    backgroundColor: "lightGrey",
    color: "black",
    fontSize: "15px"
  }
}));
