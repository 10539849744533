import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment-timezone';
import Layout from './container/Layout';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import About from './components/About';
import _404 from './container/404';
import theme from './lib/theme';

import RootPage from './container/root';

const NonIEBrowserRoute = ({ component: Component, ..._props }) => (
  <Route {..._props} render={(props) => <Component {..._props} />} />
);

const Routes = () => (
  <ThemeProvider theme={theme}>
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
      <Router>
        <Switch>
          <NonIEBrowserRoute exact path="/map/:product" component={Layout} />
          <Route path="/main/:product" component={RootPage} />
          <Route path="/privacypolicy" component={PrivacyPolicy} />
          <Route path="/termsofservice" component={TermsOfService} />
          <Route path="/about" component={About} />
          <Route path="/" component={Layout} />
          <Route component={_404} />
        </Switch>
      </Router>
    </MuiPickersUtilsProvider>
  </ThemeProvider>
);

export default Routes;
