/* eslint-disable camelcase */
/* eslint-disable no-await-in-loop */
import Numeral from 'numeral';
import moment from 'moment-timezone';

const asyncForEach = async (array, cb) => {
  for (let index = 0; index < array.length; index++) {
    await cb(array[index], index, array);
  }
};

const createDataset = (times, data) => {
  if (data && data.length > 0) {
    return data.map((item, index) => ({ x: new Date(times[index] * 1000), y: item }));
  } else {
    return null;
  }
};

const getSlice = (data, index) => {
  if (data) {
    return data.slice(index);
  } else {
    return null;
  }
};

export const isUndefined = (data, index) => data.every((i) => i === null);

function arrContains(target, pattern) {
  let value = 0;
  pattern.forEach((word) => {
    value += target.includes(word);
  });
  return value === 1;
}

export const createTimestamps = (
  start,
  min_start_time,
  max_end_time = Infinity,
  interval,
  minInterval,
  data
) => {
  const arr = [];

  let min = min_start_time;

  while (min <= start) {
    arr.push({ x: new Date(min * 1000), y: null });
    min += minInterval;
  }

  data.forEach((item, i) => {
    const current = start + i * interval;
    if (min <= current && max_end_time >= current) {
      arr.push({ x: new Date(current * 1000), y: item });
      arr.push(
        ...Array(parseInt(interval / minInterval, 10) - 1)
          .fill(0)
          .map((v, index) => ({
            x: new Date((current + (index + 1) * minInterval) * 1000),
            y: item
          }))
      );
    }
  });

  return arr;
};

export const abbreviateNumber = (value) =>
  // let newValue = value;
  // if (value >= 1000) {
  //   const suffixes = ['', 'k', 'm', 'b', 't'];
  //   const suffixNum = Math.floor(`${value}`.length / 3);
  //   let shortValue = '';
  //   for (let precision = 4; precision >= 1; precision--) {
  //     shortValue = parseFloat(
  //       (suffixNum !== 0 ? value / 1000 ** suffixNum : value).toPrecision(precision)
  //     );
  //     const dotLessShortValue = `${shortValue}`.replace(/[^a-zA-Z 0-9]+/g, '');
  //     if (dotLessShortValue.length <= 4) {
  //       break;
  //     }
  //   }
  //   if (shortValue % 1 !== 0) shortValue = shortValue.toFixed(1);
  //   newValue = shortValue + suffixes[suffixNum];
  // }
  value.toPrecision(3);

export const getPlotDatasets = ({ series, axis }, reduced = false) => {
  const { end_time_reduced: endTimeReduced, start_time_reduced: startTimeReduced } = axis;
  const seriesList = Array.isArray(series) ? series : [series];
  const minStartTime =
    reduced && startTimeReduced
      ? startTimeReduced
      : Math.min(...seriesList.map(({ start_time }) => start_time));
  const maxEndTime = reduced ? endTimeReduced : undefined;
  const minInterval = Math.min(...seriesList.map(({ interval }) => interval));

  let cntUncertaintyInterval = 0;

  const datasets = seriesList.map(
    ({ id, start_time, interval, data, style, type, yaxis, uncertainty_interval }, index) => {
      const { backgroundColor, ...restStyle } = style;
      if (uncertainty_interval) {
        cntUncertaintyInterval++;

        return {
          fill: cntUncertaintyInterval % 2 === 0 ? '-1' : '+1',
          yAxisID: yaxis,
          data: createTimestamps(start_time, minStartTime, maxEndTime, interval, minInterval, data),
          showLine: true,
          label: id,
          steppedLine: type === 'step',
          type: 'line',
          ...restStyle,
          backgroundColor: `${backgroundColor}70` // Add transparency to uncertainty area
        };
      }

      return {
        fill: false,
        yAxisID: yaxis,
        data: createTimestamps(start_time, minStartTime, maxEndTime, interval, minInterval, data),
        showLine: true,
        label: id,
        steppedLine: type === 'step',
        type: 'line',
        ...restStyle,
        backgroundColor: restStyle.borderColor
      };
    }
  );

  return { datasets };
};

export const getPlotGraphOptions = (
  {
    left_ylabel: leftYLabel,
    right_ylabel: rightYLabel,
    left_ylim: leftYLim,
    right_ylim: rightYLim,
    end_time_reduced: endTimeReduced,
    start_time_reduced: startTimeReduced
  },
  reduced = false,
  miniGraph = false // used for popup chart
) => {
  const totalHours = (endTimeReduced - startTimeReduced) / 3600;
  const MAX_X_VALUES = 10;
  const xInterval = reduced ? Math.ceil(totalHours / MAX_X_VALUES) : 1;

  const scales = {
    x: {
      type: 'time',
      time: {
        unit: 'hour',
        stepSize: xInterval,
        displayFormats: { hour: 'MMM DD, HH:mm' },
        tooltipFormat: 'MMM DD, HH:mm Z'
      },
      display: true,
      ticks: {
        fontSize: 10
      }
    },
    left: {
      id: 'left',
      position: 'left',
      type: 'linear',
      title: {
        display: true,
        text: leftYLabel
      },
      ticks: {
        fontSize: 10,
        min: leftYLim[0],
        max: leftYLim[1],
        callback(value, index, values) {
          if (miniGraph) {
            return abbreviateNumber(value);
          }
          return Numeral(value).format('0.00');
        }
      }
    }
  };

  if (rightYLabel && rightYLabel.length) {
    scales.right = {
      id: 'right',
      position: 'right',
      ticks: {
        fontSize: 10,
        min: rightYLim[0],
        max: rightYLim[1],
        callback(value, index, values) {
          if (miniGraph) {
            return abbreviateNumber(value);
          }

          return Numeral(value).format('0.00');
        }
      },
      title: {
        display: true,
        text: rightYLabel
      },
      gridLines: {
        display: false
      }
    };
  }

  return {
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      mode: 'label',
      callbacks: {
        label(tooltipItems, data) {
          return Numeral(tooltipItems.yLabel).format('0.00');
        }
      }
    },
    plugins: {
      filler: {
        propagate: false
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'xy',
          modifierKey: 'shift'
        },
        zoom: {
          mode: 'xy',
          drag: {
            enabled: true,
            borderColor: 'rgb(54, 162, 235)',
            borderWidth: 1,
            backgroundColor: 'rgba(54, 162, 235, 0.3)'
          }
        }
      }
    },
    scales,
    legend: {
      display: true
    }
  };
};

export const getBarDatasets = ({ series = [], axis, type }) => {
  if (type !== 'barchart') {
    return null;
  }
  const {
    left_ylabel: leftYabel,
    left_ylim: leftYlim,
    right_ylabel: rightYlabel,
    right_ylim: rightYlim,
    xtick_label: labels
  } = axis;
  const data = {
    labels,
    datasets: series.map(({ id, data, style }) => ({
      label: id,
      data,
      ...style
    }))
  };

  return data;
};

export const getBarGraphOptions = ({
  left_ylabel: leftYLabel,
  right_ylabel: rightYLabel,
  left_ylim: leftYLim,
  right_ylim: rightYLim,
  xtick_label: labels
}) => {
  const scales = {
    left: {
      id: 'left',
      position: 'left',
      type: 'linear',
      title: {
        display: true,
        text: leftYLabel
      },
      ticks: {
        fontSize: 10,
        min: leftYLim[0],
        max: leftYLim[1],
        callback(value, index, values) {
          return Numeral(value).format('0.00');
        }
      }
    }
  };

  if (rightYLabel && rightYLabel.length) {
    scales.right = {
      id: 'right',
      position: 'right',
      ticks: {
        fontSize: 10,
        min: rightYLim[0],
        max: rightYLim[1],
        callback(value, index, values) {
          return Numeral(value).format('0.00');
        }
      },
      title: {
        display: true,
        text: rightYLabel
      },
      gridLines: {
        display: false
      }
    };
  }

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales
  };

  return options;
};

const formatObjectArray = (objectOrArray) =>
  Array.isArray(objectOrArray) ? objectOrArray : [objectOrArray];

const preventDefault = (event) => event.preventDefault();

export const setMapPointsSession = (data) => {
  sessionStorage.setItem('mapPoints', data);
};

export const getMapPointsSession = () => sessionStorage.getItem('mapPoints');

export const removeMapPointsSession = () => {
  sessionStorage.removeItem('mapPoints');
};

// return the user data from the session storage
export const getUser = () => {
  const userStr = sessionStorage.getItem('user');
  if (userStr) return JSON.parse(userStr);
  else return null;
};

export const getPassword = () => {
  const passwordStr = sessionStorage.getItem('password');
  if (passwordStr) return JSON.parse(passwordStr);
  else return null;
};

export const getCustomerID = () => JSON.parse(sessionStorage.getItem('customerID'));

export const getCustomerName = () => JSON.parse(sessionStorage.getItem('customerName'));

// return the token from the session storage
export const getToken = () => sessionStorage.getItem('token') || null;

// remove the token and user from the session storage
export const removeUserSession = () => {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('user');
  sessionStorage.removeItem('password');
  removeMapPointsSession();
};

// set the token and user from the session storage
export const setUserSession = (token, user, password, customerID, CustomerName) => {
  sessionStorage.setItem('token', token);
  sessionStorage.setItem('user', JSON.stringify(user));
  sessionStorage.setItem('password', JSON.stringify(password));
  sessionStorage.setItem('customerID', JSON.stringify(customerID));
  sessionStorage.setItem('customerName', JSON.stringify(CustomerName));
};

export const formatDate = (val) => moment(val).format('DD-MM-YYYY');

export const formatDateTime = (val) => moment(val).format('DD-MM-YYYY HH:mm A Z');

export const makeArray = (val) => (Array.isArray(val) ? val : [val]);

const SPECIAL_COLUMNS = ['timestamp'];

export const buildAwsData = (data) => {
  if (!data) return [];
  // const result = {};

  // Object.values(data).forEach((item) => {
  //   Object.keys(item).forEach((key) => {
  //     if (!result[key]) {
  //       result[key] = item[key];
  //     } else {
  //       try {
  //         result[key].data = [...makeArray(result[key].data), ...makeArray(item[key].data)];
  //       } catch (error) {
  //         console.error(error);
  //       }
  //     }
  //   });
  // });

  // const items = [];
  // Object.keys(result).forEach((key) => {
  //   const value = result[key].data;
  //   const keyStr = key.trim();
  //   try {
  //     value.forEach((v, idx) => {
  //       if (!items[idx]) {
  //         items.push({ [keyStr]: v });
  //       } else {
  //         items[idx][keyStr] = v;
  //       }
  //     });
  //   } catch (error) {
  //     console.log('error: ', value, keyStr);
  //   }
  // });

  return data;
};

export const findVariableLongName = (options, varName) => {
  if (SPECIAL_COLUMNS.includes(varName)) return varName;
  const found = options.find((o) => o.Name.trim() === varName.trim());

  if (!found) return varName;

  return `${found.LongName || found.Name}${found.Unit ? ` (${found.Unit.trim()})` : ''}`;
};

export const findVariableUnit = (options, varName) => {
  if (SPECIAL_COLUMNS.includes(varName)) return varName;
  const found = options.find((o) => o.Name.trim() === varName.trim());

  if (!found) return '';

  return found.Unit.trim();
};

const downloadAsCsv = (data, fileName) => {
  if (!data.length) {
    return;
  }

  const csvContent = `data:text/csv;charset=utf-8,${data.map((e) => e.join(',')).join('\n')}`;
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', `${fileName}.csv`);
  document.body.appendChild(link); // Required for FF

  link.click();
};

const downloadAsImg = (imgData, fileName) => {
  const a = document.createElement('a');
  a.href = imgData;
  a.download = `${fileName}.png`;
  a.click();
};

export {
  asyncForEach,
  createDataset,
  getSlice,
  arrContains,
  formatObjectArray,
  preventDefault,
  downloadAsCsv,
  downloadAsImg
};
