import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Grid, Tooltip as Tooltips } from '@material-ui/core';
import InfoIcon from '@mui/icons-material/Info';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import AwsDataSection from './AwsDataSection';
import SidePanel from '../../components/SidePanel';
import { getAwsData, getAwsVariables } from '../../lib/api';
import { downloadAsCsv } from '../../lib/util';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    marginTop: '10px'
  },
  subContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  leftPanel: {
    padding: '0px !important'
  },
  tooltip: {
    backgroundColor: 'lightGrey',
    color: 'black',
    fontSize: '15px'
  },
  infoButton: {
    marginLeft: '10px'
  },
  rightPanel: {}
}));

const AwsData = ({ tabName, product, panel, awsId, token, dataTable, interval }) => {
  const classes = useStyles();
  const [graphCnt, setGraphCnt] = useState(1);
  const [variableRows, setVariableRows] = useState([]);
  const [rightPanelExpanded, setRightPanelExpanded] = useState(true);

  const toggleRightPanelExpanded = () => setRightPanelExpanded(!rightPanelExpanded);

  const onAddGraph = () => {
    setGraphCnt(graphCnt + 1);
  };

  const rightPanelExisting = panel?.length;

  const fetchAwsVariables = async (awsId, dataTable) => {
    try {
      const res = await getAwsVariables(product, token, awsId, dataTable);

      if (!res.status || res.status === 200) {
        setVariableRows(
          (res.VariableRows ?? []).map((row) => ({
            ...row,
            Name: row.Name?.trim() ?? '',
            Unit: row.Unit?.trim() ?? ''
          }))
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAwsData = async (variables, start, end, awsId) => {
    try {
      if (!variables.length) {
        return null;
      }

      const strVariables = variables.map((v) => v.trim()).join(',');
      const strStart = moment(start).toISOString();
      const strEnd = moment(end).toISOString();
      const res = await getAwsData(product, token, awsId, tabName, strStart, strEnd, strVariables);

      if (!res.status || res.status === 200) {
        return res;
      }
    } catch (error) {
      console.log(error);
    }
    return null;
  };

  const downloadCsv = (data) => {
    if (!data.length) {
      return;
    }

    const columns = Object.keys(data[0]);
    const timestampIndex = columns.indexOf('timestamp');
    if (timestampIndex > 0) {
      // Move 'timestamp' to the start of the array
      columns.unshift(columns.splice(timestampIndex, 1)[0]);
    }
    const units = columns.map(
      (c) => variableRows.find((v) => v.Name.trim() === c)?.Unit?.trim() ?? ''
    );
    const rows = data.map((item) => {
      const timeStr = moment(new Date(item.timestamp * 1000)).format('DD/MM/yyyy HH:mm:ss Z');

      const formatValue = (value) => {
        if (value === null) {
          return 'NaN';
        } else {
          return value;
        }
      };

      return [
        timeStr,
        ...Object.keys(item)
          .filter((key) => key !== 'timestamp')
          .map((key) => formatValue(item[key]))
      ];
    });

    rows.unshift(units);
    rows.unshift(columns);
    downloadAsCsv(rows, `${product}-${tabName}`);
  };

  useEffect(() => {
    if (awsId && dataTable) {
      fetchAwsVariables(awsId, dataTable);
    }
  }, [awsId, dataTable]);

  return (
    <>
      <Grid container spacing={4} className={classes.container}>
        <Grid
          className={classes.leftPanel}
          item
          lg={rightPanelExisting && rightPanelExpanded ? 8 : 12}
          xs={12}
        >
          <Box display="flex" width="100%" height="100%">
            <Box flex={1} p={2} overflow="hidden">
              {/* <Tooltips classes={{ tooltip: classes.tooltip }} title="testing tooltip">
              <Button variant="contained">How To Use<InfoIcon className={classes.infoButton} color="primary"/></Button>
            </Tooltips>  */}
              {new Array(graphCnt).fill(null).map((_, idx) => (
                <AwsDataSection
                  // eslint-disable-next-line react/no-array-index-key
                  key={idx}
                  number={idx + 1}
                  type="graph"
                  variableOptions={variableRows}
                  tabName={tabName}
                  product={product}
                  awsId={awsId}
                  interval={interval}
                  fetchAwsData={fetchAwsData}
                  downloadCsv={downloadCsv}
                />
              ))}
              <Box py={2}>
                <Button variant="contained" color="primary" onClick={onAddGraph}>
                  Add display
                </Button>
              </Box>
            </Box>
            {rightPanelExisting && (
              <Box
                display="flex"
                alignItems={!rightPanelExpanded ? 'flex-start' : 'center'}
                borderLeft="1px solid #eee"
                height="100%"
              >
                <Box
                  width={24}
                  height="100%"
                  display="flex"
                  alignItems="center"
                  style={{ cursor: 'pointer' }}
                  onClick={toggleRightPanelExpanded}
                >
                  {rightPanelExpanded ? <ChevronRight /> : <ChevronLeft />}
                </Box>
              </Box>
            )}
          </Box>
        </Grid>
        {rightPanelExisting && rightPanelExpanded && (
          <Grid item md={12} xs={12} lg={4}>
            <Box className={classes.rightPanel}>
              <SidePanel data={panel} />
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default AwsData;
