/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  event: undefined,
  urls: undefined,
  urlsTimestamp: 0,
  timerEnabled: true
};

export const featureSlice = createSlice({
  name: 'feature',
  initialState,
  reducers: {
    setUrls: (state, action) => {
      state.urls = action.payload.urls;
      state.urlsTimestamp = action.payload.urlsTimestamp;
    },
    clearUrls: (state) => {
      state.urls = null;
      state.urlsTimestamp = null;
    },
    setTimerEnabled: (state, action) => {
      state.timerEnabled = action.payload;
    }
  }
});

export const { setUrls, clearUrls, setTimerEnabled } = featureSlice.actions;
export const selectUrls = (state) => state.feature.urls;
export const selectUrlsTimestamp = (state) => state.feature.urlsTimestamp;

export default featureSlice.reducer;
