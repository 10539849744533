import React, { useState, useEffect, useRef } from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { Network } from 'vis-network';
import EditItemsModal from './EditItemsModal';
import Summary from './Summary';
import { formatObjectArray } from '../../lib/util';

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: '100vh',
    height: '100%',

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1)
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2.5),
      paddingTop: 0
    }
  },
  card: {
    width: 240,
    height: 'auto',
    padding: 16,
    textAlign: 'center'
  },
  centerButon: {
    margin: '0 auto'
  },
  actionButton: {
    marginRight: 20
  },
  networkContainer: {
    '& .vis-network:focus': {
      outline: 'none'
    },
    border: '1px solid #e0e0e0'
  },
  mainConatiner: {
    height: '100%'
  },
  item: {
    height: '100%',
    paddingTop: 20,
    paddingBottom: 20,

    '&:first-child': {
      paddingRight: 40,

      [theme.breakpoints.down('md')]: {
        paddingRight: 0
      }
    },

    [theme.breakpoints.down('sm')]: {
      height: '50%'
    }
  },
  legend: {
    paddingTop: '10px',
    paddingBottom: '10px'
  },
  legendItems: {
    width: '20px', 
    height: '20px', 
    marginRight: '5px'
  }

}));

const nodeSettings = {
  shape: 'box',
  font: {
    size: 16
  },
  margin: {
    top: 20,
    bottom: 20,
    left: 20,
    right: 20
  },
  fixed: true
};

const edgeSettings = {
  smooth: {
    enabled: true,
    type: 'continuous',
    roundness: 0
  }
};

const AvailabilityManager = ({ availability = {}, outage = {}, handleSubmit, feature, outageSubcomps }) => {
  const classes = useStyles();

  const [network, setNetwork] = useState(null);
  const [selectedComp, setSelectedComp] = useState(null);
  const [plannedOutage, setPlannedOutage] = useState(false);

  const { components: availabilityComps = [], nodes = [], edges = [] } = availability;
  const { components: outageComps = [] } = outage;

  const components = availabilityComps.map((aComp) => {
    const found = outageComps.find((o) => o.id === aComp.id);
    return {
      ...aComp,
      ...found,
      subcomps: formatObjectArray(aComp.subcomps).map((sc) => ({
        ...sc,
        ...(found.subcomps || []).find((fs) => fs.id === sc.id)
      }))
    };
  });

  const visContainerRef = useRef(null);

  const selected = components.find((item) => item.id === selectedComp?.id);
  const items = selected
    ? outageComps
        .find((oc) => oc.id === selected.id)
        .subcomps.map((sc) => ({
          ...sc,
          ...selected.subcomps.find((s) => s.id === sc.id)
        }))
    : [];

  const options = {};

  const onOpenItemsModal = (id) => {
    const item = components.find((l) => l.id === id);
    setSelectedComp(item);
  };

  const onCloseItemsModal = () => {
    setSelectedComp(null);
  };

  const handleUpdateSubComp = ({ id, availability, date }) => {
    if (!outageComps) {
      return;
    }

    const newSubComps = items.map((node) => {
      if (node.id === id) {
        return {
          ...node,
          plannedOutageStart: date,
          plannedOutageAvailability: availability
        };
      }
      return node;
    });

    const newComponents = outageComps.map((comp) => {
      if (comp.id === selectedComp.id) {
        return {
          ...comp,
          subcomps: newSubComps
        };
      }

      return comp;
    });

    handleSubmit({
      components: newComponents,
      nodes,
      edges
    });
  };

  const handleRemoveSubComp = ({ id }) => {
    if (!outageComps) {
      return;
    }
    const newSubComps = items.map((node) => {
      if (node.id === id) {
        return {
          ...node,
          plannedOutageStart: '',
          plannedOutageAvailability: ''
        };
      }
      return node;
    });

    const newComponents = outageComps.map((comp) => {
      if (comp.id === selectedComp.id) {
        return {
          ...comp,
          subcomps: newSubComps
        };
      }

      return comp;
    });

    handleSubmit({
      components: newComponents,
      nodes,
      edges
    });
  };

  const getNetworkData = () => {
    const nodesData = nodes.map((node) => ({ ...nodeSettings, ...node }))
    const edgesData = edges.map((edge) => ({ ...edgeSettings, ...edge }))
    
    if (outageComps.length) {
      
      const nodeIdsToChangeColor = []; 
      const colorToChange = '#FCD498';

      for (let i = 0; i < outageComps.length; i++) {
        for (let j = 0; j < outageComps[i].subcomps.length; j++) {
          if (outageComps[i].subcomps[j].plannedOutageAvailability !== "" 
            && 
            outageComps[i].subcomps[j].plannedOutageAvailability !== null) {
              nodeIdsToChangeColor.push(outageComps[i].id)
            }
        }
      }

      nodeIdsToChangeColor.forEach((nodeId) => {
        const nodeToChangeColor = nodesData.find((node) => node.id === nodeId);
        if (nodeToChangeColor) {
          nodeToChangeColor.color = {
            background: colorToChange,
            highlight: {
              background: colorToChange,
            }
          };
        }
      });

      if (nodeIdsToChangeColor.length > 0) {
        setPlannedOutage(true)
      } else {
        setPlannedOutage(false)
      }

    }
    
    return {
      nodes: nodesData,
      edges: edgesData
    }
  }


  const handleClickNode = (nodeId) => {
    const selected = nodes.find((n) => n.id === nodeId);

    if (!selected || selected.noevent) {
      return false;
    }

    return onOpenItemsModal(nodeId);
  };

  const onClickNode = (nodeIds) => {
    if (!nodeIds?.length) {
      return false;
    }

    handleClickNode(nodeIds[0]);

    return true;
  };

  useEffect(() => {
    const network =
      visContainerRef.current && new Network(visContainerRef.current, getNetworkData(), options);
    // Event handlers
    network.on('click', (properties) => {
      onClickNode(properties.nodes);
    });

    setNetwork(network);
  }, [visContainerRef]);

  useEffect(() => {
    if (network) {
      network.setData(getNetworkData(), {});
      // Event handlers
      network.on('click', (properties) => {
        onClickNode(properties.nodes);
      });
    }
  }, [edges, nodes]);

  return (
    <Box className={classes.root} display="flex" flexDirection="column">
      <Grid className={classes.legend} container>
        <Box 
          className={classes.legendItems}
          sx={{ bgcolor: '#FCD498', display: 'inline-flex' }} 
        />
        Planned Outage
        <Box 
          className={classes.legendItems}
          marginLeft='30px' 
          sx={{ bgcolor: '#97C2FC', display: 'inline-flex' }} 
        />
        No Outage Planned
      </Grid>
      <Box width="100%" flexGrow={1} maxHeight="100%">
        <Grid className={classes.mainConatiner} container>
          <Grid className={classes.item} item xs={12} lg={7}>
            <Box
              id="network-container"
              className={classes.networkContainer}
              ref={visContainerRef}
              width="100%"
              height="100%"
            />
          </Grid>
          <Grid className={classes.item} item xs={12} lg={5}>
            <Summary 
              components={components} 
              plannedOutage={plannedOutage} 
              outageSubcomps={outageSubcomps}
              onCancel={handleSubmit}
              nodes={nodes}
              edges={edges}
              tooltip={outage.tooltip}
            />
          </Grid>
        </Grid>
      </Box>

      <EditItemsModal
        compId={selectedComp?.id}
        compData={selectedComp}
        open={!!selectedComp}
        items={items}
        feature={feature}
        handleClose={onCloseItemsModal}
        onAddNewItem={handleUpdateSubComp}
        onCancelItem={handleRemoveSubComp}
      />
    </Box>
  );
};

export default AvailabilityManager;
