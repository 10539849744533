import React, { useMemo, useEffect, useState } from 'react';
import moment from 'moment';
import { Box, Button, Grid, makeStyles } from '@material-ui/core';
import { getCustomerInfo, getSuppressInfo, postSuppressInfo } from '../../lib/api';
import { getCustomerID } from '../../lib/util';
import Confirmation from '../modals/Confirmation';

const useStyles = makeStyles((theme) => ({
  suppressBtn: {
    textTransform: 'none'
  },
  suppressBtnPart: {
    textTransform: 'lowercase'
  }
}));

const SuppressActions = ({ productName, token, isMPF5 }) => {
  const classes = useStyles();
  const [suppressInfo, setSuppressInfo] = useState();
  const [componentId, setComponentId] = useState();
  const [customerInfo, setCustomerInfo] = useState();
  const [timer, setTimer] = useState();

  const customerId = getCustomerID();

  const component = useMemo(
    () => suppressInfo?.components.find((c) => c.id === componentId),
    [componentId, suppressInfo]
  );

  const fetchSuppressInfo = (productName, token) => {
    getSuppressInfo(productName, token).then((res) => {
      if (res.status && res.status !== 200) return;
      setSuppressInfo(res);
    });
  };

  const fetchCustomerInfo = async (cid) => {
    try {
      const res = await getCustomerInfo(cid, token);

      if (res.CustomerID) {
        setCustomerInfo(res);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancel = () => setComponentId(undefined);

  const handleClick = (cId) => setComponentId(cId);

  const message = useMemo(() => {
    if (!component?.customerID || !component?.timestamp) return '';
    return `Last ${component.status ? 'suppressed' : 'unsuppressed'} by ${
      customerInfo?.CustomerName
    } at ${moment(component.timestamp).format('DD/MM/yyyy hh:mm')} UTC`;
  }, [component, customerInfo]);

  const handleConfirm = async () => {
    try {
      if (component) {
        await postSuppressInfo(productName, token, {
          id: component.id,
          customerID: parseInt(customerId, 10),
          status: !component.status,
          timestamp: new Date().toISOString()
        });
        fetchSuppressInfo(productName, token);
      }
    } catch (error) {
      console.log(error);
    }
    handleCancel();
  };

  useEffect(() => {
    if (isMPF5) {
      fetchSuppressInfo(productName, token);
      setTimer(window.setInterval(() => fetchSuppressInfo(productName, token), 60 * 1000));
    }

    return () => {
      if (timer) {
        window.clearInterval(timer);
      }
    };
  }, [isMPF5, productName, token]);

  useEffect(() => {
    if (component?.customerID) {
      fetchCustomerInfo(component?.customerID);
    }
  }, [component?.customerID]);

  if (!suppressInfo?.components?.length) return null;

  return (
    <Box>
      {suppressInfo?.components && (
        <Box px={2} py={1}>
          <Grid container spacing={2}>
            {suppressInfo.components.map((item) => (
              <Grid key={item.id} item xs={12} sm={6}>
                <Button
                  className={classes.suppressBtn}
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => handleClick(item.id)}
                >
                  {item.status ? 'Unsuppress' : 'Suppress'}&nbsp;
                  <span className={classes.suppressBtnPart}>{item?.title}</span>
                </Button>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      <Confirmation
        open={!!componentId}
        title={`Confirm to ${component?.status ? 'unsuppress' : 'suppress'} ${component?.title}`}
        text={message}
        onOK={handleConfirm}
        okLabel="Confirm"
        onCancel={handleCancel}
        size="sm"
      />
    </Box>
  );
};

export default SuppressActions;
