import React, { memo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AvailabilityManager from './AvailabilityManager';
import { getAvailabilityData, postAvailabilityData } from '../../lib/api';
import { getToken } from '../../lib/util';

const Availability = memo((props) => {
  const [data, setData] = useState(null);
  const [outageSubcomps, setOutageSubcomps] = useState([]);

  const params = useParams();
  const { product } = params;
  const token = getToken();

  const fetchData = async () => {
    const data = await getAvailabilityData(product, token);

    if (data && data.availability && data.outage) {
      setData(data);
      const outageArray = [];
      data.outage.components.forEach((component) => {
        component.subcomps.forEach((subcomp) => {
          if (subcomp.plannedOutageAvailability !== null && subcomp.plannedOutageAvailability !== "") {
            outageArray.push(subcomp);
          }
        })
      })
      setOutageSubcomps(outageArray);
    }
  };

  const postData = async (data) => {
    try {
      await postAvailabilityData(product, token, data);

      await fetchData();
    } catch (error) {
      console.log('post data error: ', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  
  return <AvailabilityManager {...data} handleSubmit={postData} {...props} outageSubcomps={outageSubcomps}/>;
});

export default Availability;
