import React, { useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  makeStyles,
  Backdrop,
  CircularProgress, 
  Tooltip as Tooltips,
} from '@material-ui/core';
import moment from 'moment';
import InfoIcon from '@mui/icons-material/Info';
import pdfIcon from '../../assets/pdf.png';
import mp4Icon from '../../assets/mp4.png';
import { getReportFileLink } from '../../lib/api';
import { getToken, getUser, getPassword } from '../../lib/util';

const useStyles = makeStyles({
  container: {
    flex: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#cde7fa',
    border: '2px solid #cde7fa',
    borderRadius: 12,
    marginLeft: '1rem',
    marginRight: '1rem'
  },
  title: {
    height: 60,
    marginRight: "10px",
  },
  titleDiv: {
    whiteSpace: 'nowrap',
    // maxWidth: 120,
    width: '100%',
    overflow: 'hidden',

    '& span': {
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  },
  listWrapper: {
    flex: 1,
    overflow: 'auto',
    padding: 12
  },
  listItem: {
    padding: '0.5rem 0rem'
  },
  link: {
    overflow: 'hidden',
    flex: 1,
    paddingRight: 8,
    cursor: 'pointer'
  },
  date: {
    flexGrow: 0,
    marginLeft: 'auto',
    textAlign: 'right'
  },
  tooltip: {
    backgroundColor: "lightGrey",
    color: "black",
    fontSize: "15px"
  },
  tooltipDiv: {
    display: "flex",
  },
  titleName: {
    marginRight: "10px",
  }
});

const ReportsList = ({ title, files, tooltip }) => {
  const classes = useStyles();

  const token = getToken();
  const { product } = useParams();
  const [loading, setLoading] = useState(false);

  const downloadFile = async (fileType, fileName) => {
    try {
      setLoading(true);
      await axios
        .get(getReportFileLink(token, product, fileType, fileName), {
          responseType: 'blob',
          headers: {
            Username: getUser(),
            Password: getPassword()
          }
        })
        .then((response) => {
          // Create a Blob from the PDF Stream
          const file = new Blob([response.data], { type: `application/${fileType}` });

          // Build a URL from the file
          const fileURL = URL.createObjectURL(file);

          const a = document.createElement('a');
          document.body.appendChild(a);
          a.style = 'display: none';
          a.href = fileURL;
          a.target = '_blank';
          a.download = `${fileName}.${fileType}`;
          a.click();
          a.remove();

          if (fileType === 'pdf') {
            // Open the URL on new Window
            window.open(fileURL, '_blank');
            window.URL.revokeObjectURL(fileURL);
          }

          return true;
        })
        .catch(console.log)
        .finally(() => setLoading(false));
    } catch (error) {
      return { error };
    }

    return false;
  };

  const icon = useCallback((type) => {
    if (type === 'pdf') {
      return <img src={pdfIcon} alt="PDF icon" width={40} height={40} />;
    }

    return <img src={mp4Icon} alt="MP4 icon" width={40} height={40} />;
  }, []);

  return (
    <>
      <Box py={2} className={classes.container}>
        <Box p={2} pb={3} className={classes.title}>
          <div className={classes.tooltipDiv}>
            <Typography className={classes.titleName} align="left" variant="h6">
              {title}
            </Typography>
            {tooltip && <>
                          <Tooltips  classes={{ tooltip: classes.tooltip }} title={tooltip}>
                            <InfoIcon color="primary"/>
                          </Tooltips>
                        </>
            }
          </div>
        </Box>
        <List className={classes.listWrapper}>
          {files.map(({ id, title, link, date, type }, index) => (
            <ListItem key={id || index} className={classes.listItem}>
              <ListItemIcon>{icon(type)}</ListItemIcon>
              <Link
                title={title}
                className={classes.link}
                onClick={() => downloadFile(type, title)}
              >
                <ListItemText className={classes.titleDiv}>{title}</ListItemText>
              </Link>
              <ListItemText className={classes.date}>
                {moment(date * 1000).format('DD/MM/yyyy hh:mm')}
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Box>

      <Backdrop style={{ color: '#fff', zIndex: 9999 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default ReportsList;
