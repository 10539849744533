import React from 'react';
import { useParams } from 'react-router-dom';
import { getMapPointsSession } from '../../lib/util';
import LegacyPage from '../legacy';
import MainPage from '../main';
import StationPage from '../station';

const RootPage = (props) => {
  const { product } = useParams();
  const features = JSON.parse(getMapPointsSession() || '[]');
  const feature = features.find((f) => f.properties.Name === product);
  const isCFS = feature.properties.service === 'CFS';
  const isAWS = feature.properties.service === 'aws';

  if (isAWS) {
    return <StationPage feature={feature} {...props} />;
  }
  if (isCFS) {
    return <MainPage feature={feature} {...props} />;
  }
  return <LegacyPage feature={feature} {...props} />;
};

export default RootPage;
