import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  typography: {
    fontFamily: ['Merriweather Sans']
  }
});

theme.typography.h1 = {
  fontSize: '6rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '4rem'
  }
};

theme.typography.h2 = {
  fontSize: '3.75rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '2.5rem'
  }
};

theme.typography.h3 = {
  fontSize: '3rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem'
  }
};

theme.typography.h4 = {
  fontSize: '2.125rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem'
  }
};

theme.typography.h5 = {
  fontSize: '1.5rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem'
  }
};

theme.typography.h6 = {
  fontSize: '1.25rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.75rem'
  }
};

theme.typography.subtitle1 = {
  fontSize: '1rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.7rem'
  }
};

theme.typography.body1 = {
  fontSize: '1rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.7rem'
  }
};

export default theme;
