/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable camelcase */
import { Box, makeStyles, Grid } from '@material-ui/core';
import React, { useState, useEffect, memo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { getWeather } from '../../lib/api';
import { getToken } from '../../lib/util';
import FullChartModal from '../../components/Chart/FullChartModal';
import SidePanel from '../../components/SidePanel';
import { setTimerEnabled } from '../../redux/slices/feature';
import Chart from '../../components/Chart/Chart';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse'
    },
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row'
    }
  },
  wrapper: {
    [theme.breakpoints.down('sm')]: {
      padding: 0
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2)
    }
  },
  box: {
    padding: theme.spacing(2.5)
  },
  titleWrapper: {
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(0.5)
    }
  },
  fullChartBtn: {
    position: 'absolute',
    top: theme.spacing(0.5),
    right: 0
  },
  graphWrapper: {
    maxWidth: '100vw',
    padding: 8,

    [theme.breakpoints.down('md')]: {
      padding: 0
    }
  },
  leftPanel: {
    padding: '0px !important'
  }
}));

const WeatherPage = memo(() => {
  const [data, setData] = useState({});
  const [selected, setSelected] = useState(null);
  const [timer, setTimer] = useState(null);

  const [autoRefreshEnabled, setAutoRefreshEnabled] = useState(true);
  const [rightPanelExpanded, setRightPanelExpanded] = useState(true);

  const toggleRightPanelExpanded = () => setRightPanelExpanded(!rightPanelExpanded);

  const toggleAutoRefreshEnabled = () => setAutoRefreshEnabled(!autoRefreshEnabled);

  const timerEnabled = useSelector(({ feature }) => feature.timerEnabled);

  const dispatch = useDispatch();

  const { product } = useParams();
  const token = getToken();

  const classes = useStyles();

  const onClickZoomIn = (key) => {
    setSelected(key);
  };

  const onCloseFullChart = () => {
    setSelected(null);
  };

  const fetchData = async () => {
    try {
      const resp = await getWeather(product, token);
      if (resp) {
        setData(resp);
      }
    } catch (error) {
      console.error(error);
      setData(null);
    }
  };

  useEffect(() => {
    fetchData();

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, []);

  useEffect(() => {
    if (timerEnabled) {
      if (!timer) {
        const interval = setInterval(fetchData, 60000);
        setTimer(interval);
      }
    } else if (timer) {
      clearInterval(timer);
      setTimer(null);
    }
  }, [timerEnabled]);

  useEffect(() => {
    dispatch(setTimerEnabled(autoRefreshEnabled));
  }, [autoRefreshEnabled]);

  if (!data) {
    return null;
  }

  const { panel, graph = [] } = data;
  const showRightPanel = panel && (panel.length || Object.keys(panel).length);

  return (
    <Box>
      <Box className={classes.wrapper}>
        <Grid className={classes.container} container spacing={4}>
          <Grid
            className={classes.leftPanel}
            item
            lg={showRightPanel && rightPanelExpanded ? 8 : 12}
            xs={12}
          >
            <Box display="flex" width="100%" height="100%">
              <Box flex={1} p={2} overflow="hidden">
                <Grid container>
                  {graph.map((value, key) => (
                    <Chart
                      id={value.id || key}
                      chartData={value}
                      onClickZoomIn={onClickZoomIn}
                      toggleAutoRefresh={toggleAutoRefreshEnabled}
                      autoRefresh={autoRefreshEnabled}
                    />
                  ))}
                </Grid>
              </Box>
              {showRightPanel && (
                <Box
                  display="flex"
                  alignItems={!rightPanelExpanded ? 'flex-start' : 'center'}
                  borderLeft="1px solid #eee"
                  height="100%"
                >
                  <Box
                    width={24}
                    height="100%"
                    display="flex"
                    alignItems="center"
                    style={{ cursor: 'pointer' }}
                    onClick={toggleRightPanelExpanded}
                  >
                    {rightPanelExpanded ? <ChevronRight /> : <ChevronLeft />}
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>
          {showRightPanel && rightPanelExpanded && (
            <Grid item lg={4} xs={12}>
              <SidePanel data={panel} />
            </Grid>
          )}
        </Grid>
      </Box>

      <FullChartModal
        open={selected > -1 && !Number.isNaN(selected)}
        onClose={onCloseFullChart}
        data={graph[selected]}
      />
    </Box>
  );
});

export default WeatherPage;
