import { configureStore } from '@reduxjs/toolkit';
import auth from './slices/auth';
import aws from './slices/aws';
import feature from './slices/feature';
import location from './slices/location';

const store = configureStore({
  reducer: {
    auth,
    aws,
    feature,
    location
  }
});

export default store;
