/* eslint-disable arrow-body-style */
import React, { useMemo, useRef } from 'react';
import {
  Grid,
  Typography,
  makeStyles,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Tooltip as Tooltips,
} from '@material-ui/core';
import { Bar, Line } from 'react-chartjs-2';
import 'chartjs-adapter-moment';
import moment from 'moment';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { Menu as MenuIcon } from '@material-ui/icons';
import InfoIcon from '@mui/icons-material/Info';
import {
  getPlotDatasets,
  getPlotGraphOptions,
  getBarDatasets,
  getBarGraphOptions,
  downloadAsImg,
  downloadAsCsv
} from '../../lib/util';
import CapacityForecastTable from '../CapacityForecastTable';
import ProaMenu from '../menu';

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  zoomPlugin
);

const useStyles = makeStyles((theme) => ({
  grid: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0)
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2.5)
    }
  },
  box: {
    height: '100%',
    minHeight: 400,
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0)
    }
  },
  title: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(0)
    }
  },
  titleText: {
    marginRight: theme.spacing(2)
  },
  graphContainer: {
    flex: 1,
    width: '100%'
  },
  fullChartBtn: {
    position: 'absolute',
    top: theme.spacing(0.5),
    right: 0
  },
  resetBtn: {
    fontSize: '0.75rem'
  },
  tooltip: {
    backgroundColor: "lightGrey",
    color: "black",
    fontSize: "15px"
  }
}));

const Chart = ({
  id,
  chartData: { title, series, axis, type, size, tooltip },
  onClickZoomIn,
  width = 6,
  toggleAutoRefresh,
  autoRefresh,
  menuVisible = true
}) => {
  const classes = useStyles();
  const chartRef = useRef(null);

  const graph = useMemo(() => {
    if (type === 'barchart') {
      const data = getBarDatasets({ series, axis, type });
      const options = getBarGraphOptions(axis);

      return <Bar ref={chartRef} data={data} options={options} />;
    }

    if (type === 'timeseries') {
      const data = getPlotDatasets({ series, axis }, true);
      const options = getPlotGraphOptions(axis, true);
      return (
        <Line
          ref={chartRef}
          data={data}
          options={options}
          datasetKeyProvider={() => Math.random()}
        />
      );
    }

    if (type === 'table') {
      return <CapacityForecastTable data={{ series, axis }} />;
    }

    return null;
  }, [series, axis, type]);

  const isChart = type === 'barchart' || type === 'timeseries';

  const downloadImg = () => {
    const imgData = chartRef.current.toBase64Image();
    downloadAsImg(imgData, title);
  };

  const downloadCsv = () => {
	  
	const seriesList = Array.isArray(series) ? series : [series];

	if (type === 'timeseries') {
		const list = seriesList.map(({ data, start_time: startTime, interval }) => {
		  return data.map((item, idx) => ({
			timestamp: startTime + interval * idx,
			value: item
		  }));
		});
		const timeSeries = [
		  ...new Set(
			list.flatMap((subList) => subList.map((item) => item.timestamp)).sort((a, b) => a - b)
		  )
		];
		const data = timeSeries.map((ts) => {
		  return [
			moment(new Date(ts * 1000)).format('DD/MM/yyyy hh:mm:ss'),
			...list.map((subList) => subList.find((item) => item.timestamp === ts)?.value ?? '')
		  ];
		});
		data.unshift(['Timestamp', series.map((s) => s.id)]);
		
		downloadAsCsv(data, title);
	} 
	
	if (type === 'barchart') {
		const {xtick_label:labels} = axis;
		const list = series.map((item) => item.data);
		const listTransposed = list[0].map((_, colIndex) => list.map(row => row[colIndex]));
		const data = listTransposed.map((x, i) => [labels[i], x]);
		data.unshift(['Timestamp', series.map((s) => s.id)]);		
		downloadAsCsv(data, title);
	}
	
    
  };

  const resetZoom = () => {
    chartRef.current.resetZoom();
  };

  const menuItems = [
    {
      label: 'View in full screen',
      onClick: () => onClickZoomIn(id)
    },
    {
      label: 'Reset zoom',
      onClick: resetZoom
    },
    {
      label: 'Download CSV',
      onClick: downloadCsv
    },
    {
      label: 'Download image',
      onClick: downloadImg
    },
    {
      label: (
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox checked={!!autoRefresh} onChange={toggleAutoRefresh} color="primary" />
            }
            label="Toggle auto refresh"
          />
        </FormGroup>
      )
      // onClick: toggleAutoRefresh
    }
  ];

  return (
    <Grid className={classes.grid} item xs={12} md={width}>
      <Grid container className={classes.box}>
        <Grid item className={classes.title}>
          <Typography className={classes.titleText} variant="h6" align="center">
            {title}
          </Typography>
          {tooltip && <>
                          <Tooltips  classes={{ tooltip: classes.tooltip }} title={tooltip}>
                            <InfoIcon color="primary"/>
                          </Tooltips>
                        </>
          }
          {isChart && menuVisible && (
            <Box position="absolute" top={0} right={0}>
              <ProaMenu label={<MenuIcon />} items={menuItems} />
            </Box>
          )}
        </Grid>
        <Grid item className={classes.graphContainer}>
          <div id="mini-graph" style={{ height: '400px' }}>
            {graph}
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Chart;
