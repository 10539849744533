/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable new-cap */
/* eslint-disable no-use-before-define */
import olMap from 'ol/Map';
import olView from 'ol/View';
// import olProj from 'ol/proj';
import * as olProj from 'ol/proj';
import olOverlay from 'ol/Overlay';

// import { Component } from 'react';
import 'ol/ol.css';

import olFormatGeoJSON from 'ol/format/GeoJSON';
import olSourceVector from 'ol/source/Vector';
import olSourceCluster from 'ol/source/Cluster';
import olLayerVector from 'ol/layer/Vector';

import olStyleStyle from 'ol/style/Style';
import olStyleIcon from 'ol/style/Icon';
import olStyleReg from 'ol/style/RegularShape';
import olStyleStroke from 'ol/style/Stroke';
import olStyleCircle from 'ol/style/Circle';
import olStyleFill from 'ol/style/Fill';
import olStyleText from 'ol/style/Text';
import { OSM } from 'ol/source';
import { Tile } from 'ol/layer';

import osm from './layers/osm';
import layers from '../assets/layers.json';

import rooftop from '../assets/icons/rooftop.svg';
import network from '../assets/icons/network.svg';
import solarfarm from '../assets/icons/solarfarm.svg';
import station from '../assets/icons/metstation1.svg';
import { getUser, getPassword, getToken } from './util';

const factories = { osm };

const loadMap = (mapDiv, props, zoom) => {
  const map = new olMap({
    loadTilesWhileAnimating: true,
    target: mapDiv,
    layers: [],
    view: new olView({
      // center: olProj.fromLonLat([140, -30]),
      // zoom: 4.8,
      // minZoom: 4,
      // maxZoom: 19
      center: olProj.fromLonLat([zoom[0], zoom[1]]),
      zoom: zoom[2],
      minZoom: 4,
      maxZoom: 19
    })
  });
  addLayers(map);
  return map;
};

const registerEvent = (map, selectFeature, setHoveredFeature, reset, popupDiv) => {
  const overlay = getOverlay(map, popupDiv);

  map.on('click', async (e) => {
    const features = map.getFeaturesAtPixel(e.pixel, { hitTolerance: 5 });

    if (features) {
      const feature = features[0];
      // const coordinates = feature.getGeometry().getCoordinates();

      try {
        setHoveredFeature(null);
        if (feature.values_.features.length === 1) {
          // overlay.setPosition(coordinates);
          const { Name } = { ...feature.values_.features[0].values_ };
          selectFeature(Name);
        } else {
          // map.getView().setCenter(transform(e.coordinate, 'EPSG:4326', 'EPSG:3857'));
          map.getView().setCenter(e.coordinate);
          map.getView().animate({
            zoom: map.getView().getZoom() + 4,
            duration: 250
          });
        }
      } catch (e) {
        console.log(e);
        selectFeature(null);
        overlay.setPosition(undefined);
        reset();
      }
    } else {
      setTimeout(() => {
        reset();
        selectFeature(null);
        overlay.setPosition(undefined);
      }, 100);
    }
  });

  map.on('pointermove', async (e) => {
    const features = map.getFeaturesAtPixel(e.pixel, { hitTolerance: 5 });

    if (features) {
      const feature = features[0];

      try {
        if (feature.values_.features.length > 0) {
          setHoveredFeature(feature.values_.features.map((f) => f.values_));
        }
      } catch (e) {
        console.log(e);
        setHoveredFeature(null);
        overlay.setPosition(undefined);
        reset();
      }
    } else {
      setHoveredFeature(null);
    }
  });
};

const showOverlay = (map, popupId, featureName) => {
  let result = false;

  if (!map) {
    return false;
  }

  map?.getLayers().forEach((l) => {
    const { features: layers } = l.getSource();

    if (layers) {
      const mapFeatures = layers.flatMap((l) => l.values_.features);
      const selected = mapFeatures.find((mf) => mf.values_.Name === featureName);
      const overlay = getOverlay(map, popupId);
      const coordinates = selected.getGeometry().getCoordinates();
      overlay.setPosition(coordinates);
      result = true;
    }
  });

  return result;
};

const hideOverlay = (map, popupDiv) => {
  const overlay = getOverlay(map, popupDiv);

  if (overlay) {
    overlay.setPosition(undefined);
  }
};

const getOverlay = (map, popupDiv) => {
  const existingOverlay = map.getOverlayById(popupDiv);

  if (existingOverlay) {
    return existingOverlay;
  }

  const container = document.getElementById(popupDiv);
  // eslint-disable-next-line new-cap
  const overlay = new olOverlay({
    id: popupDiv,
    element: container,
    autoPan: true,
    autoPanAnimation: {
      duration: 250
    },
    stopEvent: true
  });

  map.addOverlay(overlay);
  return overlay;
};

const loadFeatures = (map, data) => {
  if (!map) {
    return;
  }

  const source = new olSourceVector({ wrapX: false });

  const features = new olFormatGeoJSON().readFeatures(data, { featureProjection: 'EPSG:3857' });
  setPointStyle(features);

  for (let j = 0; j < features.length; j++) {
    if (data.features[j].properties.type.includes('cluster')) {
      setNetworkStyle(features[j]);
    }
    if (data.features[j].properties.type.includes('rooftop')) {
      setRooftopStyle(features[j]);
    }
    if (data.features[j].properties.type.includes('aws')) {
      setAwsStyle(features[j]);
    }
  }
  source.addFeatures(features);
  // remove layer if it exists before adding it back
  removeLayer(map, 'points');
  // map.addLayer(new olLayerVector({ source, name: 'points' }));

  const clusterSource = new olSourceCluster({
    distance: 80,
    source
  });

  const getIconStyle = (type) => {
    const icon = new olStyleIcon(
      /** @type {olx.style.IconOptions} */ ({
        anchor: [0.5, 0.65],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        opacity: 1,
        // eslint-disable-next-line no-nested-ternary
        src:
          type === 'cluster'
            ? network
            : type === 'rooftop'
            ? rooftop
            : type === 'aws'
            ? station
            : solarfarm,
        size: [89.53, 89.53],
        scale: 0.75
      })
    );

    return new olStyleStyle({ image: icon });
  };

  const styleCache = {};

  const clusters = new olLayerVector({
    source: clusterSource,
    style(feature) {
      const size = feature.get('features').length;
      let style = styleCache[size];

      if (size === 1) {
        return getIconStyle(feature.get('features')[0].values_.type);
      }
      if (!style) {
        style = new olStyleStyle({
          image: new olStyleCircle({
            radius: 35,
            // stroke: new olStyleStroke({
            //   color: '#fff'
            // }),
            fill: new olStyleFill({
              color: '#329ae9'
            })
          }),
          text: new olStyleText({
            text: size.toString(),
            fill: new olStyleFill({
              color: '#fff'
            }),
            font: 'normal 30px sans-serif'
          })
        });
        styleCache[size] = style;
      }
      return style;
    }
  });
  map.addLayer(clusters);
};

const findClosest = function (x, arr) {
  const indexArr = arr.map((k) => Math.abs(k - x));
  const min = Math.min(...indexArr);
  return indexArr.indexOf(min);
};

const calculateCentre = async (props) => {
  // also returns the display name
  let product;
  let token;
  let dataPath;
  if (props.match) {
    dataPath = process.env.REACT_APP_API_URL;
  } else {
    token = getToken();
    product = props.computedMatch.params.product;
    dataPath = `${process.env.REACT_APP_API_URL}/MapPoints/${token}/${product}`;
  }

  const dlons = [
    360, 180, 90, 45, 22.5, 11.25, 5.625, 2.813, 1.406, 0.703, 0.352, 0.176, 0.088, 0.044, 0.022,
    0.011, 0.005, 0.003, 0.001, 0.0005, 0.00025
  ];
  const zooms = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
  let ave_lon = 0;
  let ave_lat = 0;
  let max_lon = 100;
  let min_lon = 170;
  let max_lat = -47;
  let min_lat = -6;
  let idx;
  let idx_lon;
  let idx_lat;
  // var datap
  return fetch(dataPath, {
    headers: {
      'Content-Type': 'application/json',
      Username: getUser(),
      Password: getPassword()
    }
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.features) {
        for (let i = 0; i < data.features.length; i++) {
          ave_lon += data.features[i].geometry.coordinates[0];
          ave_lat += data.features[i].geometry.coordinates[1];
          max_lon = Math.max(max_lon, data.features[i].geometry.coordinates[0]);
          min_lon = Math.min(min_lon, data.features[i].geometry.coordinates[0]);
          max_lat = Math.max(max_lat, data.features[i].geometry.coordinates[1]);
          min_lat = Math.min(min_lat, data.features[i].geometry.coordinates[1]);
        }

        ave_lat /= data.features.length;
        ave_lon /= data.features.length;
        idx_lon = findClosest(max_lon - min_lon, dlons) + 1;
        idx_lat = findClosest(max_lat - min_lat, dlons) + 1;
        idx = Math.min(idx_lat, idx_lon);
        const zoom_data = [ave_lon, ave_lat, zooms[idx]];
        const DisplayName = data.title || data.features[0].properties.DisplayName;
        if (data.features.length === 1) {
          zoom_data[2] = 4.8;
        }
        return [zoom_data, DisplayName];
      }

      return null;
    });
};

const removeLayer = (map, name) => {
  let tempLayer;

  if (!map) {
    return true;
  }

  map.getLayers().forEach((l) => {
    if (name === l.values_.name) {
      tempLayer = l;
    }
  });

  if (tempLayer) {
    map.removeLayer(tempLayer);
  }

  return true;
};

const addLayers = (map) => {
  const useLocalImgs = !!process.env.REACT_APP_USE_LOCAL_IMGS;

  if (useLocalImgs) {
    // This is the layer that uses the locally stored tiles
    const newLayer = new Tile({
      source: new OSM({
        name: 'Local Tiles',
        url: '/tiles/{z}/{x}/{y}.png',
        numZoomLevels: 11,
        alpha: true,
        isBaseLayer: false
      })
    });
    map.addLayer(newLayer);
  } else {
    layers.basemap.forEach((layer) => {
      const { type } = layer.layer;
      if (type in factories) {
        map.addLayer(factories[type](layer.layer));
      }
    });
  }
};

function setPointStyle(features) {
  features.forEach((feat) => {
    const style = [
      new olStyleStyle({
        image: new olStyleIcon(
          /** @type {olx.style.IconOptions} */ ({
            anchor: [0.5, 0.65],
            anchorXUnits: 'fraction',
            anchorYUnits: 'fraction',
            opacity: 1,
            src: solarfarm,
            size: [89.53, 89.53],
            scale: 0.75
          })
        )
      }),
      new olStyleStyle({
        image: new olStyleReg({
          stroke: new olStyleStroke({ color: [0, 0, 0, 0] }),
          anchor: [1.9, 0.9],
          points: 4,
          radius: 40, // <--------- control its size
          angle: Math.PI / 4
        })
      })
    ];

    feat.setStyle(style);
  });
}

function setNetworkStyle(feat) {
  const style = [
    new olStyleStyle({
      image: new olStyleIcon(
        /** @type {olx.style.IconOptions} */ ({
          anchor: [0.5, 0.65],
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          src: network
        })
      )
    }),
    new olStyleStyle({
      image: new olStyleReg({
        stroke: new olStyleStroke({ color: [0, 0, 0, 0] }),
        anchor: [1.9, 0.9],
        points: 4,
        radius: 40, // <--------- control its size
        angle: Math.PI / 4
      })
    })
  ];

  feat.setStyle(style);
}

function setRooftopStyle(feat) {
  const style = [
    new olStyleStyle({
      image: new olStyleIcon(
        /** @type {olx.style.IconOptions} */ ({
          anchor: [0.5, 0.65],
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          src: rooftop
        })
      )
    }),
    // add invisible rectangle over icon to make clickable box bigger
    new olStyleStyle({
      image: new olStyleReg({
        stroke: new olStyleStroke({ color: [0, 0, 0, 0] }),
        anchor: [1.9, 0.9],
        points: 4,
        radius: 40, // <--------- control its size
        angle: Math.PI / 4
      })
    })
  ];

  feat.setStyle(style);
}

function setAwsStyle(feat) {
  const style = [
    new olStyleStyle({
      image: new olStyleIcon(
        /** @type {olx.style.IconOptions} */ ({
          anchor: [0.5, 0.65],
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          src: station
        })
      )
    }),
    // add invisible rectangle over icon to make clickable box bigger
    new olStyleStyle({
      image: new olStyleReg({
        stroke: new olStyleStroke({ color: [0, 0, 0, 0] }),
        anchor: [1.9, 0.9],
        points: 4,
        radius: 40, // <--------- control its size
        angle: Math.PI / 4
      })
    })
  ];

  feat.setStyle(style);
}

export { loadMap, registerEvent, loadFeatures, calculateCentre, showOverlay, hideOverlay };
