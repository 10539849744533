import React from 'react';
import radar from '../assets/radar.gif';
import './Preloader.css';

const ScatterGraph = (props) => (
  <div id="radar" {...props}>
    <img src={radar} alt="radar" />
  </div>
);

export default ScatterGraph;
