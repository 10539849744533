/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  makeStyles,
  Link,
  Button
} from '@material-ui/core';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import logo from '../assets/logo.png';
import './Header.css';
import { getMapPoints, getSummary } from '../lib/api';
import { getUser, removeUserSession, getToken } from '../lib/util';
import { clearToken, selectToken } from '../redux/slices/auth';

const useStyles = makeStyles((theme) => ({
  appbar: {
    backgroundColor: 'rgb(50, 154, 233)'
  },
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(3)
  },
  title: {
    flexGrow: 1,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logo: {
    position: 'relative',
    display: 'inline-block',
    height: 50,
    maxHeight: '100%',
    marginTop: 0
  },
  menuItem: {
    minHeight: 30
  },
  backLink: {
    paddingLeft: theme.spacing(2),
    color: '#fff',

    '&:hover': {
      color: '#fff',
      textDecoration: 'none'
    }
  }
}));

const Header = ({ displayName }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const token = useSelector(selectToken) || getToken();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [sites, setSites] = useState(null);

  const history = useHistory();

  const user = getUser();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const checkData = async (features) => {
    try {
      const siteProperties = features.map((feature) => feature.properties);
      const sortedSites = siteProperties.sort((a, b) => a.DisplayName.localeCompare(b.DisplayName));
      const filteredSites = sortedSites.filter((site) => site.service !== '');
      setSites(filteredSites);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (token && user) {
      getMapPoints(user, token).then((res) => {
        if (res.features) {
          checkData(res.features);
        }
      });
    }
  }, []);

  const handleLogout = () => {
    dispatch(clearToken());
    handleClose();
    removeUserSession();
    history.push('/');
  };

  const initialTab = (idx) => {
    const { service } = sites[idx];
    const { Name } = sites[idx];
    if (service.includes('CFS')) {
      return '?tab=monitoring';
    } else if (Name === 'ugl_tailembend2') {
      return '';
    } else if (Name === 'IGSP_Five_Min' || Name === 'IGSR_Five_Min') {
      return '?tab=Five_Min';
    } else if (Name === 'CGS_Ten_Min') {
      return '?tab=Ten_Min';
    } else if (service.includes('aws') && !service.includes('MPF5')) {
      return '?tab=Weather';
    } else {
      return '?tab=forecast';
    }
  };

  return (
    <AppBar className={classes.appbar} position="static">
      <Toolbar>
        <img className={classes.logo} src={logo} alt="Proa Analytics Logo" />
        <Box flex={1}>
          {displayName ? (
            <Typography variant="h6" className={classes.title}>
              {displayName}
            </Typography>
          ) : user ? (
            <Link className={classes.backLink} href={`/map/${user}`}>
              Back to map
            </Link>
          ) : null}
        </Box>
        {user && (
          <>
            <Button
              className={classes.menuButton}
              color="inherit"
              variant="outlined"
              aria-label="menu"
              onClick={handleClick}
            >
              Select Site
              <ArrowDropDownIcon />
            </Button>
            <Button variant="outlined" color="inherit" onClick={handleLogout}>
              Logout
            </Button>
          </>
        )}
        {sites && (
          <Menu
            id="user-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            style={{ marginTop: '35px' }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            {sites.map((site, idx) => (
              <MenuItem
                key={site.DisplayName}
                className={classes.menuItem}
                component={Link}
                href={`/main/${site.Name}${initialTab(idx)}`}
                style={{ outline: 'none', textDecoration: 'none' }}
              >
                {site.DisplayName}
              </MenuItem>
            ))}
          </Menu>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
