/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  makeStyles,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import WarningIcon from '@material-ui/icons/Warning';
import SuppressActions from '../../components/SuppressActions';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    border: '2px solid #a0a0a0',
    borderRadius: 4,
    marginBottom: theme.spacing(4.5),
    backgroundColor: '#fff',

    '&:first-child': {
      marginTop: theme.spacing(2.5)
    },
    '&:last-child': {
      marginBottom: theme.spacing(5)
    },

    [theme.breakpoints.down('sm')]: {
      marginLeft: 12,
      marginRight: 12,
      '&:first-child': {
        marginTop: 0
      }
    }
  },
  accordion: {
    '&.MuiAccordion-root.Mui-expanded': {
      marginBottom: 0
    }
  },
  rootSummary: {
    '& .MuiAccordionSummary-content': {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5)
    },

    '&.MuiAccordionSummary-root.Mui-expanded': {
      minHeight: theme.spacing(6),
      [theme.breakpoints.down('sm')]: {
        minHeight: 0
      }
    }
  },
  rootDetails: {
    padding: 0
  },
  titleOnly: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 16px',
    minHeight: theme.spacing(6),
    borderBottom: '1px solid rgba(0, 0, 0, 0.27)',
    transition:
      'min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

    [theme.breakpoints.down('sm')]: {
      minHeight: theme.spacing(4)
    }
  },
  title: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    cursor: 'pointer',
    color: '#337ab7',

    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  messagesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 48
  },
  message: {
    paddingTop: 6,
    paddingBottom: 6
  },
  icon: {
    minWidth: 36,
    height: 24,
    textAlign: 'left'
  },
  summaryHeader: {
    display: 'flex',
    alignItems: 'center'
  },
  warningIcon: {
    color: '#ffe100',
    background: '#fff'
  },
  linksBox: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: 10,

    '& a': {
      color: '#337ab7',
      padding: '0 8px'
    }
  },
  suppressBtn: {
    textTransform: 'none'
  },
  suppressBtnPart: {
    textTransform: 'lowercase'
  }
}));

const SummarySection = ({ metadata, data, showPopup, awsInfo, token }) => {
  const classes = useStyles();
  const { summary } = data;
  const siteName = metadata.id || metadata.Name;
  const rows = awsInfo[siteName] || [];

  const onClickTitle = () => {
    showPopup(metadata.Name);
  };

  if (!metadata || !summary) {
    return null;
  }

  const service = Array.isArray(metadata.service) ? metadata.service : [metadata.service];
  const isCFS = service.includes('CFS');
  const isAWS = service.includes('aws');
  const isMPF5 = service.includes('MPF5');
  const isMultiAws = (isCFS && isAWS) || (isMPF5 && isAWS);

  return (
    <Box className={classes.container}>
      <Accordion className={classes.accordion} key={siteName} defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id={siteName}
          className={classes.rootSummary}
        >
          <Box className={classes.title} onClick={onClickTitle}>
            <Typography variant="h5">{metadata.DisplayName}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails className={classes.rootDetails}>
          <Box display="flex" flexDirection="column" flex={1} overflow="auto">
            <Box flex={1}>
              {summary.map(({ id, title, warning, messages }, index) =>
                messages.length ? (
                  <Accordion key={id || index}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} id={`item${index}`}>
                      <Box className={classes.summaryHeader}>
                        <Box className={classes.icon}>
                          {!!warning && <WarningIcon className={classes.warningIcon} />}
                        </Box>
                        <Typography variant="subtitle1">{title}</Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box className={classes.messagesWrapper}>
                        {messages.length ? (
                          messages.map((m, idx) => (
                            <Box key={m.id || idx} className={classes.message}>
                              {m}
                            </Box>
                          ))
                        ) : (
                          <Box className={classes.message}>No messages</Box>
                        )}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                ) : (
                  <Box className={classes.titleOnly}>
                    <Box className={classes.summaryHeader}>
                      <Box className={classes.icon}>
                        {!!warning && <WarningIcon className={classes.warningIcon} />}
                      </Box>
                      <Typography variant="subtitle1">{title}</Typography>
                    </Box>
                  </Box>
                )
              )}
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      <SuppressActions productName={metadata.Name} token={token} isMPF5={isMPF5} />
      <Box className={classes.linksBox}>
        {isCFS ? (
          <>
            <Link href={`/main/${siteName}?tab=monitoring`}>Monitoring</Link>
            <Link href={`/main/${siteName}?tab=weather`}>Weather</Link>
            <Link href={`/main/${siteName}?tab=availability`}>Availability</Link>
            <Link href={`/main/${siteName}?tab=reporting`}>Reporting</Link>
            {isMultiAws && <Link href={`/main/${siteName}?tab=data`}>Data</Link>}
          </>
        ) : isMPF5 ? (
          <>
            <Link href={`/main/${siteName}?tab=forecast`}>Forecast</Link>
            <Link href={`/main/${siteName}?tab=history`}>History</Link>
            <Link href={`/main/${siteName}?tab=performance_mpf5`}>Performance</Link>
            <Link href={`/main/${siteName}?tab=weather`}>Weather</Link>
            <Link href={`/main/${siteName}?tab=reporting`}>Reporting</Link>
            {isMultiAws && <Link href={`/main/${siteName}?tab=data`}>Data</Link>}
          </>
        ) : isAWS && !isMultiAws ? (
          <>
            {rows.map(({ DataTable }) => (
              <Link key={DataTable} href={`/main/${siteName}?tab=${DataTable}`}>
                {DataTable}
              </Link>
            ))}
          </>
        ) : (
          <>
            <Link href={`/main/${siteName}?tab=forecast`}>Forecast</Link>
            <Link href={`/main/${siteName}?tab=history`}>History</Link>
            <Link href={`/main/${siteName}?tab=weather`}>Weather</Link>
            <Link href={`/main/${siteName}?tab=reporting`}>Reporting</Link>
          </>
        )}
      </Box>
    </Box>
  );
};

export default SummarySection;
