import React, { useState, useEffect, memo } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid, makeStyles } from '@material-ui/core';
import ReportsList from './ReportsList';
import Header from '../../components/Header';
import { getReportsList, getReportFileLink } from '../../lib/api';
import { formatObjectArray, getToken } from '../../lib/util';

const useStyles = makeStyles((theme) => ({
  list: {
    flex: 1,
    minWidth: '25%',
    paddingBottom: 30,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flex: 'auto'
    }
  },
  page: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    height: '100%'
  },
  content: {
    flex: 1,
    paddingTop: 30,
    paddingBottom: 30,
    overflow: 'auto'
  }
}));

const ReportingPage = memo(({ match, withHeaders }) => {
  const classes = useStyles();
  const { product } = useParams();
  const token = getToken();

  const [reports, setReports] = useState([]);

  const fetchReportsList = async () => {
    try {
      const res = await getReportsList(product, token);

      setReports(
        res.data.map((item) => ({
          ...item,
          files: formatObjectArray(item.files).map((file) => ({
            ...file,
            link: getReportFileLink(token, product, file.type, file.title)
          }))
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchReportsList();
  }, []);

  return (
    <div>
      {withHeaders && <Header params={match.params} />}
      <Box className={classes.page}>
        <Grid container justify="center" className={classes.content}>
          {reports.map(({ id, title, files, tooltip }, index) => (
            <Grid key={id || index} item className={classes.list}>
              <ReportsList title={title} files={files} tooltip={tooltip} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
});

export default ReportingPage;
