import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Divider
} from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginLeft: 16,
    marginRight: 16,
    minWidth: 300
  },
  divider: {
    marginTop: 16,
    marginBottom: 48
  },
  button: {
    marginLeft: 16
  }
}));

const DATE_TIME_FORMAT = 'DD-MM-YYYY hh:mm A Z';

const EditItem = ({
  open,
  id,
  name,
  feature,
  partialAvailabilityEnabled,
  onAddNewItem,
  handleSave,
  handleClose
}) => {
  const classes = useStyles();

  const [newAvailability, setNewAvailability] = useState(0);
  const [newDate, setNewDate] = useState(moment().add(1, 'hours'));
  const [saving, setSaving] = useState(false);
  const [isError, setIsError] = useState(false);

  const onChangeDate = (val) => {
    setNewDate(val);
  };

  const onChangeAvailability = (e) => {
	setIsError(false)
    setNewAvailability(e.target.value);
  };

  const onConfirm = () => {
    // Just to test
    setSaving(true);
	// check value is 0 to 100
	if (newAvailability < 0 || newAvailability >100) {
		setIsError(true);
		setSaving(false);
		return;
	}
    handleSave({
      id,
      newAvailability,
      newDate: newDate.toISOString()
    });
  };

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      onClose={handleClose}
      aria-labelledby="edit-items-modal"
      maxWidth="md"
      fullWidth
    >
      <DialogContent>
        <Typography variant="h5">{name}</Typography>
        <Divider className={classes.divider} />
        <Box p={2} pb={3}>
          <Box>
            Input planned outage in local site time ({feature?.properties?.timezone}), ignoring
            daylight savings time
          </Box>
        </Box>
        <Grid container direction="row" alignItems="center" justify="flex-start" p={0}>
          <FormControl className={classes.formControl}>
            <DateTimePicker
              label="Planned Outage Start"
              inputVariant="outlined"
              value={newDate}
              format={DATE_TIME_FORMAT}
              onChange={onChangeDate}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            {partialAvailabilityEnabled ? (
			  <>
			   <InputLabel id="demo-simple-select-label">Planned Outage Availability</InputLabel>
			     <Select
					label="Planned Outage Availability"
					id="demo-simple-select"
					value={newAvailability}
					onChange={onChangeAvailability}
				  >
				    <MenuItem value={0}>0%</MenuItem>
				    <MenuItem value={100}>100%</MenuItem>
				  </Select>
			  </>
            ) : (
				<TextField
				id="outlined-basic"
                label="Planned Outage Availability (%)"
				value={newAvailability}
                onChange={onChangeAvailability}
				variant="outlined"
				type="number"
        InputProps={{ inputProps: { min: 0, max: 100 } }}
				error={isError}
				helperText={isError ? "Availability must be between 0 and 100" : "" }
				/>
              /* <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={newAvailability}
                onChange={onChangeAvailability}
              >
                <MenuItem value={0}>0%</MenuItem>
                <MenuItem value={10}>10%</MenuItem>
                <MenuItem value={20}>20%</MenuItem>
                <MenuItem value={30}>30%</MenuItem>
                <MenuItem value={40}>40%</MenuItem>
                <MenuItem value={50}>50%</MenuItem>
                <MenuItem value={60}>60%</MenuItem>
                <MenuItem value={70}>70%</MenuItem>
                <MenuItem value={80}>80%</MenuItem>
                <MenuItem value={90}>90%</MenuItem>
                <MenuItem value={100}>100%</MenuItem>
              </Select> */
			  
            )}
          </FormControl>

          {/* {changed && (
            <FormControl className={classes.formControl}>
              <Grid container>
                <IconButton onClick={onConfirm}>
                  <CheckCircleOutlineIcon color="primary" fontSize="large" />
                </IconButton>
                <IconButton onClick={onCancel}>
                  <HighlightOffIcon color="error" fontSize="large" />
                </IconButton>
              </Grid>
            </FormControl>
          )} */}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box p={2} display="flex">
          <Box maxWidth={400}>
            {moment(newDate).valueOf() - moment().valueOf() < 30 * 60 * 1000 ? (
              <Typography color="error" variant="body2">
                Warning: outages can be submitted for a time within 30 minutes of current time but
                may violate the rules of the GPS
              </Typography>
            ) : null}
          </Box>
          {saving ? (
            <Button variant="outlined" disabled>
              Saving...
            </Button>
          ) : (
            <Button
              className={classes.button}
              variant="outlined"
              color="primary"
              onClick={onConfirm}
            >
              SAVE
            </Button>
          )}
          <Button
            className={classes.button}
            variant="outlined"
            color="secondary"
            onClick={handleClose}
          >
            CLOSE
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default EditItem;
