/* eslint-disable react/no-unused-state */
/* eslint-disable no-use-before-define */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import {
  loadMap,
  loadFeatures,
  registerEvent,
  calculateCentre,
  showOverlay,
  hideOverlay
} from '../lib/maps';
import { getMapPoints, getPopupData } from '../lib/api';
import Popup from '../components/Popup';
import Footer from '../components/Footer';
import Header from '../components/Header';
import './Layout.css';
import '../index.css';
import Login from './login/Login';
import Summary from './summary';
import { getUser, getToken, setMapPointsSession } from '../lib/util';
import { clearLocation, selectLocation, setLocation } from '../redux/slices/location';
import { fetchAwsTableInfo } from '../redux/slices/aws';
import { selectToken } from '../redux/slices/auth';

const mapId = 'map';
const popupId = 'popup';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  }
}));

const Layout = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const token = useSelector(selectToken) || getToken();
  const location = useSelector(selectLocation);

  const [valid, setValid] = useState(false);
  const [displayName, setDisplayName] = useState('');
  const [map, setMap] = useState(null);
  const [features, setFeatures] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [hoveredFeature, setHoveredFeature] = useState(null);
  const [loadingPopup, setLoadingPopup] = useState(false);

  const timer = useRef(null);
  const params = useParams();
  const classes = useStyles();

  const resetLocation = (payload) => {
    dispatch(clearLocation(payload));
  };

  const handler = async () => {
    let zoom = [];
    let displayName = '';

    const user = getUser();

    const data = await calculateCentre(props);

    if (!data) {
      return;
    }

    if (valid === 'private') {
      if (user) {
        if (props.match) {
          zoom = [140, -30, 4.8];
        } else {
          [zoom, displayName] = data;
        }
      }
    } else if (props.match) {
      zoom = [140, -30, 4.8];
    } else {
      [zoom, displayName] = data;
    }

    if (displayName) {
      setDisplayName(displayName);
    }

    if (zoom.length) {
      const mapInstance = loadMap(mapId, props, zoom);

      registerEvent(mapInstance, setSelectedFeature, setHoveredFeature, resetLocation, popupId);

      setMap(mapInstance);

      // reloads features every minute
      timer.current = setInterval(load, 60 * 1000);
    }
  };

  const load = useCallback(() => {
    const { product } = params;
    if (token && product) {
      getMapPoints(product, token).then((res) => {
        if (res.features) {
          setFeatures(res.features);
          setMapPointsSession(JSON.stringify(res.features));
        }
        loadFeatures(map, res);
      });
    }
  }, [map, token, params?.product]);

  useEffect(() => {
    if (!token) {
      history.push('/');
    }
  }, [token]);

  useEffect(() => {
    if (map) {
      load();
    }
  }, [map, params]);

  useEffect(() => {
    try {
      handler();
    } catch (error) {
      console.log(error);
    }

    return () => timer.current && clearInterval(timer.current);
  }, []);

  useEffect(() => {
    if (selectedFeature) {
      resetLocation();

      if (showOverlay(map, popupId, selectedFeature)) {
        setLoadingPopup(true);
        getPopupData(selectedFeature, token)
          .then((res) => {
            dispatch(setLocation(res));
          })
          .finally(() => setLoadingPopup(false));
      }
    } else {
      resetLocation();
    }
  }, [selectedFeature, token]);

  useEffect(() => {
    if (selectedFeature) {
      return;
    }

    if (hoveredFeature && hoveredFeature.length) {
      setSelectedFeature(null);
      resetLocation();
      dispatch(setLocation({ hoveredFeature }));
      showOverlay(map, popupId, hoveredFeature[0].Name);
    } else {
      resetLocation();
    }
  }, [hoveredFeature, selectedFeature]);

  useEffect(() => {
    if (!map || !popupId) {
      return;
    }

    if (location) {
      if (selectedFeature) {
        showOverlay(map, popupId, selectedFeature);
      }
    } else {
      hideOverlay(map, popupId);
    }
  }, [map, popupId, location]);

  useEffect(() => {
    if (!features?.length) return;
    const awsFeatures = features.filter((f) => f.properties.service === 'aws');

    awsFeatures.forEach((af) => {
      dispatch(
        fetchAwsTableInfo({
          name: af.properties.Name,
          token
        })
      );
    });
  }, [features]);

  const user = getUser();

  const feature = features.find((f) => f.properties.Name === selectedFeature);

  if (props.path === '/map/:product') {
    if (user) {
      return (
        <div className={classes.root}>
          <Header displayName={displayName} />
          <div
            style={{
              display: 'flex',
              flex: 1,
              width: '100%',
              zIndex: 0,
              overflow: 'auto',
              position: 'relative'
            }}
          >
            <div style={{ flex: 1, height: '100%' }} id={mapId} />
            <div>
              <Summary showPopup={setSelectedFeature} features={features} />
            </div>
          </div>
          <div
            id={popupId}
            className={`ol-popup ${selectedFeature ? 'static' : 'flexible'}`}
            onMouseUp={convertToClick}
          >
            <Popup
              location={location}
              feature={feature}
              selectFeature={setSelectedFeature}
              loading={loadingPopup}
            />
          </div>
          <Footer />
        </div>
      );
    } else if (valid === 'private') {
      return (
        <div>
          <Header />
          <Login />
          <Footer />
        </div>
      );
    } else {
      return (
        <div className={classes.root}>
          <Header displayName={displayName} />
          <div style={{ flex: 1, width: '100%', zIndex: 0 }} id={mapId} />
          <div id={popupId} className="ol-popup" onMouseUp={convertToClick}>
            <Popup location={location} />
          </div>
          <Footer />
        </div>
      );
    }
  } else {
    return (
      <div>
        <Header />
        <Login />
        <Footer />
      </div>
    );
  }
};

// fix to enable firing of onClick events on openlayers popups
const convertToClick = (e) => {
  e.stopPropagation();
  const evt = new MouseEvent('click', { bubbles: true });
  evt.stopPropagation = () => {};
  e.target.dispatchEvent(evt);
};

export default Layout;
