import * as React from 'react';
import { Box, Dialog, AppBar, Toolbar, IconButton, Slide, DialogContent } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const FullScreenModal = ({ open, headerVisible, handleClose, children }) => (
  <div>
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      {headerVisible ? (
        <AppBar position="relative">
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      ) : null}
      <DialogContent>
        <Box>{children}</Box>
      </DialogContent>
    </Dialog>
  </div>
);

export default FullScreenModal;
