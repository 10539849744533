import React, { useState, useEffect, useCallback } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tooltip as Tooltips
} from '@material-ui/core';
import InfoIcon from '@mui/icons-material/Info';
import { DateTimePicker } from '@material-ui/pickers';
import { ExpandMore } from '@material-ui/icons';
import { useStyles } from './styles';

const DATE_TIME_FORMAT = 'DD-MM-YYYY hh:mm A Z';

const DATE_AGO_OPTIONS = [
  {
    id: 0,
    label: 'Last hour',
    value: 1
  },
  {
    id: 1,
    label: 'Last 24 hours',
    value: 24
  },
  {
    id: 2,
    label: 'Last 7 days',
    value: 24 * 7
  },
  {
    id: 0,
    label: 'Last month',
    value: 30 * 24
  }
];

const FORMAT_OPTIONS = [
  {
    id: 0,
    label: 'Table',
    value: 'table'
  },
  {
    id: 1,
    label: 'Graph',
    value: 'graph'
  },
  {
    id: 2,
    label: 'Both table & graph',
    value: 'both'
  }
];

const Filters = ({
  tabName, // 'Ten Min', 'Diagnostic' etc
  variableOptions,
  start,
  end,
  setStart,
  setEnd,
  setAwsData,
  setLoading,
  format,
  setFormat,
  product,
  awsId,
  fetchAwsData
}) => {
  const classes = useStyles();
  const [timer, setTimer] = useState(null);
  const [variables, setVariables] = useState([]);
  const [initiated, setInitiated] = useState(false);
  const [ago, setAgo] = useState(24 * 7);
  const [isAutoRefreshOn, setIsAutoRefreshOn] = useState(false);

  const onResetVariables = () => setVariables([]);

  const handleChangeAutoRefresh = (event) => {
    setIsAutoRefreshOn(event.target.checked);
  };

  const onChangeVariables = (e) => {
    if (e.target.checked) {
      setVariables([...variables, e.target.name]);
    } else {
      setVariables(variables.filter((v) => v !== e.target.name));
    }
  };

  const onChangeStart = (val) => {
    setStart(val);
    setAgo('');
  };

  const onChangeEnd = (val) => {
    setEnd(val);
    setAgo('');
  };

  const handleChangeAgo = (e) => {
    setAgo(e.target.value);
  };

  const handleChangeFormat = (e) => {
    setFormat(e.target.value);
  };

  const getAwsData = useCallback(async () => {
    setLoading(true);
    const data = await fetchAwsData(variables, start, end, awsId);
    if (data) {
      setAwsData(data);
    }
    setLoading(false);
  }, [variables, start, end, awsId]);

  const onUpdateFilters = async () => {
    getAwsData();
  };

  useEffect(() => {
    if (variableOptions.length) {
      setVariables(variableOptions.slice(0, 2).map((vo) => vo.Name));
      setInitiated(true);
    }
  }, [variableOptions]);

  useEffect(() => {
    if (ago) {
      setStart(new Date().getTime() - ago * 3600 * 1000);
      setEnd(new Date());
    }
  }, [ago]);

  useEffect(() => {
    if (tabName) {
      getAwsData();
    }
  }, [tabName]);

  useEffect(() => {
    if (initiated) {
      getAwsData();
    }
  }, [initiated]);

  useEffect(() => {
    getAwsData();

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, []);

  useEffect(() => {
    if (timer) {
      clearInterval(timer);
    }

    if (isAutoRefreshOn) {
      const interval = setInterval(() => getAwsData(), 60000);
      setTimer(interval);
    }
  }, [getAwsData, isAutoRefreshOn]);

  return (
    <Box py={2}>
      <Accordion>
        <AccordionSummary className={classes.accordionHeader} expandIcon={<ExpandMore />}>
          Show Filters
        </AccordionSummary>
        <AccordionDetails className={classes.accordionContent}>
          <Grid container spacing={2}>
            <Grid item sm={12} md={3}>
              <FormControl fullWidth>
                <InputLabel id="quick-select-label" variant="outlined">
                  Format to display
                </InputLabel>
                <Select
                  value={format}
                  name="quick-select"
                  labelId="format-label"
                  onChange={handleChangeFormat}
                  displayEmpty
                  variant="outlined"
                  label="Format to display"
                >
                  {FORMAT_OPTIONS.map(({ id, label, value }) => (
                    <MenuItem key={id} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={12} md={3}>
              <FormControl fullWidth>
                <InputLabel id="quick-select-label" variant="outlined">
                  Quick select
                </InputLabel>
                <Select
                  value={ago}
                  name="quick-select"
                  labelId="quick-select-label"
                  onChange={handleChangeAgo}
                  displayEmpty
                  variant="outlined"
                  label="Quick select"
                >
                  {DATE_AGO_OPTIONS.map(({ id, label, value }) => (
                    <MenuItem key={id} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={12} md={3}>
              <DateTimePicker
                label="Choose start date"
                inputVariant="outlined"
                value={start}
                format={DATE_TIME_FORMAT}
                onChange={onChangeStart}
                fullWidth
              />
            </Grid>
            <Grid item sm={12} md={3}>
              <DateTimePicker
                label="Choose end date"
                inputVariant="outlined"
                value={end}
                format={DATE_TIME_FORMAT}
                onChange={onChangeEnd}
                fullWidth
              />
            </Grid>

            {variableOptions.map((vo) => (
              <Grid key={vo.Name} className={classes.checkboxWrapper} item xs={6} sm={3}>
                <Box>
                  <FormGroup>
                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={onChangeVariables}
                            color="primary"
                            checked={variables.includes(vo.Name)}
                          />
                        }
                        label={vo.LongName || vo.Name}
                        name={vo.Name}
                      />
                      <Tooltips  classes={{ tooltip: classes.tooltip }} title={vo.Description}>
                        <InfoIcon color="primary"/>
                      </Tooltips>
                    </div>
                  </FormGroup>
                </Box>
              </Grid>
            ))}
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" justifyContent="flex-end" px={4} py={2}>
                <Box pr={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isAutoRefreshOn}
                        onChange={handleChangeAutoRefresh}
                        color="primary"
                      />
                    }
                    label="Auto Refresh Data"
                  />
                </Box>
                <Box pr={2}>
                  <Button variant="outlined" color="primary" onClick={onResetVariables}>
                    Reset variables
                  </Button>
                </Box>

                <Box>
                  <Button variant="contained" color="primary" onClick={onUpdateFilters}>
                    Update data
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default Filters;
