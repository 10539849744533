/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAwsTableInfo } from '../../lib/api';

const initialState = {
  info: {}
};

export const fetchAwsTableInfo = createAsyncThunk(
  'aws/fetchAwsTableInfo',
  async ({ name, token }, thunkAPI) => {
    const res = await getAwsTableInfo(name, token);
    return { name, data: res.InfoRows };
  }
);

export const awsSlice = createSlice({
  name: 'aws',
  initialState,
  reducers: {
    setInfo: (state, action) => {
      state.info = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAwsTableInfo.fulfilled, (state, action) => {
      state.info[action.payload.name] = action.payload.data;
    });
  }
});

export const { setInfo } = awsSlice.actions;
export const selectAwsInfo = (state) => state.aws.info;

export default awsSlice.reducer;
