import React, { useState } from 'react';
import moment from 'moment';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Tooltip as Tooltips
} from '@material-ui/core';
import InfoIcon from '@mui/icons-material/Info';
import { makeStyles, styled } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { formatObjectArray } from '../../lib/util';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(3)
  }
}));

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    border: '1px solid #e0e0e0',
    overflow: 'auto'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  title: {
    fontSize: theme.typography.pxToRem(24),
    textAlign: 'center'
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  accordionDetails: {
    padding: 0
  },
  tableContainer: {
    height: '100%',
    padding: 0
  },
  table: {},
  tableHeader: {
    maxWidth: 120
  },
  tableCell: {
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 8
  },
  cancelOutageButton: {
    color: '#F50057',
    borderColor: '#F50057'
  },
  tooltip: {
    backgroundColor: 'lightGrey',
    color: 'black',
    fontSize: '15px',
    marginLeft: '10px'
  },
  summaryHeading: {
    marginRight: '10px'
  },
  tooltipDiv: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

export default function Summary({
  components,
  plannedOutage,
  onCancel,
  nodes,
  edges,
  outageSubcomps,
  tooltip
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box p={2}>
        <div className={classes.tooltipDiv}>
          <Typography className={classes.summaryHeading} variant="h5" align="center">
            Summary
          </Typography>
          {tooltip && (
            <>
              <Tooltips classes={{ tooltip: classes.tooltip }} title={tooltip}>
                <InfoIcon color="primary" />
              </Tooltips>
            </>
          )}
        </div>
      </Box>
      <Box px={2}>
        <SummaryTable
          components={components}
          plannedOutage={plannedOutage}
          onCancel={onCancel}
          nodes={nodes}
          edges={edges}
          outageSubcomps={outageSubcomps}
        />
      </Box>
    </div>
  );
}

const SummaryTable = ({ components, plannedOutage, onCancel, nodes, edges, outageSubcomps }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancelAll = () => {
    const updatedComponents = components.map((component) => {
      const updatedSubcomps = component.subcomps.map((subcomp) => {
        if (
          subcomp.plannedOutageAvailability !== null &&
          subcomp.plannedOutageAvailability !== ''
        ) {
          return {
            ...subcomp,
            plannedOutageStart: '',
            plannedOutageAvailability: ''
          };
        } else {
          return subcomp;
        }
      });
      return {
        ...component,
        subcomps: updatedSubcomps
      };
    });
    onCancel({
      components: updatedComponents,
      nodes,
      edges
    });
    setOpen(false);
  };

  return (
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeader}>
              <Typography variant="subtitle1" align="center">
                Component
              </Typography>
            </TableCell>
            <TableCell className={classes.tableHeader} align="center">
              <Typography variant="subtitle1" align="center">
                Current Avail.
              </Typography>
            </TableCell>
            <TableCell className={classes.tableHeader} align="center">
              <Typography variant="subtitle1" align="center">
                Planned outage avail.
              </Typography>
            </TableCell>
            <TableCell className={classes.tableHeader} align="center">
              <Typography variant="subtitle1" align="center">
                Planned outage start
              </Typography>
            </TableCell>
          </TableRow>
          {plannedOutage && (
            <TableRow>
              <TableCell align="center" colSpan={4}>
                <Button
                  className={classes.cancelOutageButton}
                  size="small"
                  variant="outlined"
                  onClick={handleClickOpen}
                >
                  Cancel All Planned Outages
                </Button>
                <BootstrapDialog
                  open={open}
                  TransitionComponent={Transition}
                  onClose={handleClose}
                  maxWidth="md"
                  fullWidth
                  sx={{ p: 10 }}
                >
                  <DialogTitle>Confirmation</DialogTitle>
                  <DialogContent>
                    <Typography variant="h6">
                      Are you sure you want to cancel all the following planned outages?
                    </Typography>
                    <TableContainer>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">Component</TableCell>
                            <TableCell align="center">Current Avail.</TableCell>
                            <TableCell align="center">Planned outage avail.</TableCell>
                            <TableCell align="center">Planned outage start</TableCell>
                          </TableRow>
                          {outageSubcomps.map((subcomp) => (
                            <TableRow>
                              <TableCell align="center">{subcomp.name}</TableCell>
                              <TableCell align="center">{subcomp.currentAvailability}</TableCell>
                              <TableCell align="center">
                                {subcomp.plannedOutageAvailability}
                              </TableCell>
                              <TableCell align="center">
                                {moment(subcomp.plannedOutageStart).format('DD/MM/YY HH:mm Z')}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableHead>
                      </Table>
                    </TableContainer>
                  </DialogContent>

                  <DialogActions>
                    <Button variant="outlined" color="primary" onClick={handleCancelAll}>
                      Yes
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={handleClose}>
                      No
                    </Button>
                  </DialogActions>
                </BootstrapDialog>
              </TableCell>
            </TableRow>
          )}
        </TableHead>
        <TableBody>
          {components.flatMap(({ id, subcomps, title }) => (
            <>
              <TableRow key={`title_${id}`} rowSpan={4}>
                <Box py={1}>
                  <Typography variant="body1">{title}</Typography>
                </Box>
              </TableRow>
              {formatObjectArray(subcomps).map(
                ({
                  currentAvailability,
                  id,
                  name,
                  plannedOutageAvailability,
                  plannedOutageStart
                }) => (
                  <TableRow key={id}>
                    <TableCell padding="none" className={classes.tableCell}>
                      <Typography variant="body1">{name}</Typography>
                    </TableCell>
                    <TableCell align="center" padding="none">
                      <Typography variant="body1">{currentAvailability}</Typography>
                    </TableCell>
                    <TableCell align="center" padding="none">
                      <Typography variant="body1">{plannedOutageAvailability}</Typography>
                    </TableCell>
                    <TableCell align="center" padding="none">
                      <Typography variant="body1">
                        {plannedOutageStart
                          ? moment(plannedOutageStart).format('DD/MM/YY HH:mm Z')
                          : ''}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              )}
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

// export default Summary;
