/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { getSignInData } from '../../lib/api';
import { getUser, setUserSession } from '../../lib/util';
import { setAuthInfo } from '../../redux/slices/auth';

const theme = createTheme({
  typography: {
    fontSize: 16,
    htmlFontSize: 16
  }
});

function Login(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const username = useFormInput('');
  const password = useFormInput('');
  const [error, setError] = useState(null);

  const user = getUser();

  // handle button click of login form
  const handleLogin = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    await signIn();
  };

  const signIn = async (props) => {
    const { ApiKey, Message, CustomerID, CustomerName } = await getSignInData(
      username.value,
      password.value
    );

    if (ApiKey) {
      setLoading(false);
      setUserSession(ApiKey, username.value, password.value, CustomerID, CustomerName);

      const datapath = `/map/${username.value}`;

      // Redirect to the desired location

      // let authData = {username: username.value, password: password.value};
      dispatch(
        setAuthInfo({
          username: username.value,
          token: ApiKey
        })
      );

      history.push(datapath);
      // appState:{username:username.value, password:password.value}});
    } else {
      setLoading(false);
      setError(Message);
    }
  };

  useEffect(() => {
    if (user) {
      history.push(`/map/${user}`);
    }
  }, [user]);

  return (
    <ThemeProvider theme={theme}>
      <form
        onSubmit={handleLogin}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <div style={{ marginTop: 100 }}>
          Username
          <br />
          <input type="text" {...username} autoComplete="new-password" size="30" />
        </div>
        <div style={{ marginTop: 15 }}>
          Password
          <br />
          <input type="password" {...password} autoComplete="new-password" size="30" />
        </div>
        {error && (
          <>
            <small style={{ color: 'red' }}>{error}</small>
            <br />
          </>
        )}
        <br />
        <Button
          variant="outlined"
          // color="primary"
          type="submit"
          size="large"
          disabled={loading}
        >
          <span style={{ fontSize: 14 }}>{loading ? 'Loading...' : 'Login'}</span>
        </Button>
        <br />
      </form>
    </ThemeProvider>
  );
}

const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    setValue(e.target.value);
  };
  return {
    value,
    onChange: handleChange
  };
};

export default Login;
