import React, { useEffect, useState, memo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { makeStyles, AppBar, Grid, Tabs, Tab, Box } from '@material-ui/core';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import CurrentStatus from '../../components/LocationLayout/CurrentStatus';
import {
  getForecastData,
  getPerformanceMPF5Data,
  getAwsMetadata,
  getAwsTableInfo
} from '../../lib/api';
import LocationLayout from '../forecast/LocationLayout';
import LocationHistoryLayout from '../locationhistory/LocationHistoryLayout';
import Reporting from '../reporting';
import Weather from '../weather';
import { useQuery } from '../../lib/hooks/useQuery';
import { getToken } from '../../lib/util';
import PerformanceMPF5 from '../performanceMPF5';
import AwsData from '../awsData';

const PAGES = {
  FORECAST: 'forecast',
  HISTORY: 'history',
  PERFORMANCEMPF5: 'performance_mpf5',
  WEATHER: 'weather',
  REPORTING: 'reporting',
  DATA: 'data'
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    flexGrow: 1,
    flexWrap: 'nowrap',
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto'
  },
  tabs: {
    backgroundColor: theme.palette.background.default,

    '& .MuiTab-root': {
      [theme.breakpoints.down('sm')]: {
        minWidth: theme.spacing(15)
      },
      [theme.breakpoints.down('xs')]: {
        minWidth: theme.spacing(5)
      }
    },

    '& .MuiTab-wrapper': {
      [theme.breakpoints.down('sm')]: {
        fontSize: 12
      }
    }
  },
  tabPanel: {
    flex: 1,
    overflow: 'auto',

    '& > div': {
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
        paddingBottom: theme.spacing(3)
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(2.5)
      }
    }
  }
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    value: Object.values(PAGES)[index]
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box height="100%" overflow="auto">
          {children}
        </Box>
      )}
    </div>
  );
}

const LegacyPage = memo((props) => {
  const history = useHistory();
  const classes = useStyles();
  const query = useQuery();
  const tab = query.get('tab');
  const [value, setValue] = useState(tab || localStorage.getItem('refreshTab'));
  const [status, setStatus] = useState(null);
  const [performanceData, setPerformanceData] = useState(null);
  const [forecastdata, setForecastdata] = useState({});
  const [timer, setTimer] = useState(null);
  const [awsTableInfo, setAwsTableInfo] = useState([]);
  const [metadata, setMetadata] = useState({});

  const timerEnabled = useSelector(({ feature }) => feature.timerEnabled);

  const token = getToken();
  const { product } = useParams();

  const found = awsTableInfo?.[0];
  const awsId = found?.AwsID;
  const dataTable = found?.DataTable;
  const timezone = found?.Timezone;
  const interval = parseInt(found?.Interval || '300', 10);

  const handleChange = (event, newValue) => {
    const params = new URLSearchParams();
    params.delete('tab');
    history.push({ search: params.toString() });
    setValue(newValue);
    localStorage.setItem('refreshTab', newValue);
  };

  const fetchPerformranceData = async () => {
    try {
      const res = await getPerformanceMPF5Data(product, token);

      if (!res.status || res.status === 200) {
        setPerformanceData(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchForecastData = async () => {
    try {
      const resultforecast = await getForecastData(product, token);
      setStatus(resultforecast.table);
      setForecastdata(resultforecast);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAwsTableInfo = async () => {
    try {
      const res = await getAwsTableInfo(product, token);

      if (!res.status || res.status === 200) {
        setAwsTableInfo(res.InfoRows);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAwsMetadata = async () => {
    try {
      const res = await getAwsMetadata(product, token);

      if (!res.status || res.status === 200) {
        setMetadata(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAllData = () => {
    fetchForecastData();
    fetchPerformranceData();
  };

  useEffect(() => {
    fetchAllData();

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, []);

  useEffect(() => {
    if (timerEnabled) {
      if (!timer) {
        const interval = setInterval(fetchAllData, 60000);
        setTimer(interval);
      }
    } else if (timer) {
      clearInterval(timer);
      setTimer(null);
    }
  }, [timerEnabled]);

  useEffect(() => {
    if (!token) {
      history.push('/');
    } else {
      fetchPerformranceData();
    }
  }, [token]);

  useEffect(() => {
    if (props.feature?.properties?.timezone) {
      moment.tz.setDefault(props.feature?.properties?.timezone);
    }
  }, [props.feature]);

  useEffect(() => {
    fetchAwsTableInfo();
    fetchAwsMetadata();
  }, []);

  useEffect(() => {
    if (timezone) {
      moment.tz.setDefault(timezone);
    }
  }, [timezone]);

  const service = Array.isArray(props.feature.properties.service)
    ? props.feature.properties.service
    : [props.feature.properties.service];
  const isAWS = service.includes('aws');

  return (
    <>
      <Header params={props.match.params} />
      <Grid className={classes.root} container direction="column">
        {status && <CurrentStatus summary={status} canDownload={false} tooltip={forecastdata.tooltip} />}
        <AppBar position="static">
          <Tabs
            className={classes.tabs}
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Forecast" {...a11yProps(0)} />
            <Tab label="History" {...a11yProps(1)} />
            {performanceData && <Tab label="Performance" {...a11yProps(2)} />}
            <Tab label="Weather" {...a11yProps(3)} />
            <Tab label="Reporting" {...a11yProps(4)} />
            {isAWS && <Tab label="Data" {...a11yProps(5)} />}
          </Tabs>
        </AppBar>
        <TabPanel className={classes.tabPanel} value={value} index={PAGES.FORECAST}>
          <LocationLayout data={forecastdata} {...props} />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value={value} index={PAGES.HISTORY}>
          <LocationHistoryLayout {...props} />
        </TabPanel>
        {performanceData && (
          <TabPanel className={classes.tabPanel} value={value} index={PAGES.PERFORMANCEMPF5}>
            <PerformanceMPF5 data={performanceData} {...props} />
          </TabPanel>
        )}
        <TabPanel className={classes.tabPanel} value={value} index={PAGES.WEATHER}>
          <Weather {...props} />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value={value} index={PAGES.REPORTING}>
          <Reporting {...props} />
        </TabPanel>
        {isAWS && (
          <TabPanel className={classes.tabPanel} value={value} index={PAGES.DATA}>
            <AwsData
              tabName="Data"
              product={product}
              token={token}
              panel={metadata?.panel}
              awsId={awsId}
              dataTable={dataTable}
              interval={interval}
            />
          </TabPanel>
        )}
      </Grid>
      <Footer />
    </>
  );
});

export default LegacyPage;
