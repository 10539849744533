import React, { useEffect, useState, memo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { AppBar, Grid, Tabs, Tab } from '@material-ui/core';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import CurrentStatus from '../../components/LocationLayout/CurrentStatus';
import { getMonitoring, getPerformanceMPF5Data } from '../../lib/api';
import Availability from '../availability';
import Monitoring from '../monitoring';
import Reporting from '../reporting';
import Weather from '../weather';
import Performance from '../performanceMPF5';
import TabPanel from '../../components/TabPanel';
import { useQuery } from '../../lib/hooks/useQuery';
import { getToken } from '../../lib/util';
import { useStyles } from './styles';

const PAGES = {
  MONITORING: 'monitoring',
  WEATHER: 'weather',
  PERFORMANCEMPF5: 'performance_mpf5',
  AVAILABILITY: 'availability',
  REPORTING: 'reporting'
};

function a11yProps(index) {
  return {
    id: `main-tab-${index}`,
    'aria-controls': `main-tabpanel-${index}`,
    value: Object.values(PAGES)[index]
  };
}

const MainPage = memo(({ match, feature }) => {
  const history = useHistory();
  const classes = useStyles();
  const query = useQuery();
  const tab = query.get('tab');
  const [value, setValue] = useState(tab || localStorage.getItem('refreshTab'));
  const [status, setStatus] = useState(null);
  const [performanceData, setPerformanceData] = useState(null);
  const [timer, setTimer] = useState(null);

  const { product } = useParams();
  const token = getToken();

  const timerEnabled = useSelector(({ feature }) => feature.timerEnabled);

  // Monitoring json has status table data
  const fetchMonitoringData = async () => {
    try {
      const resp = await getMonitoring(product, token);
      if (resp) {
        setStatus(resp);
      }
    } catch (error) {
      console.error(error);
      setStatus(null);
    }
  };

  const fetchPerformranceData = async () => {
    try {
      const res = await getPerformanceMPF5Data(product, token);

      if (!res.status || res.status === 200) {
        setPerformanceData(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAllData = () => {
    fetchMonitoringData();
    fetchPerformranceData();
  };

  const handleChange = (event, newValue) => {
    const params = new URLSearchParams();
    params.delete('tab');
    history.push({ search: params.toString() });
    setValue(newValue);
    localStorage.setItem('refreshTab', newValue);
  };

  useEffect(() => {
    fetchAllData();

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, []);

  useEffect(() => {
    if (timerEnabled) {
      if (!timer) {
        const interval = setInterval(fetchAllData, 60000);
        setTimer(interval);
      }
    } else if (timer) {
      clearInterval(timer);
      setTimer(null);
    }
  }, [timerEnabled]);

  useEffect(() => {
    if (!token) {
      history.push('/');
    }
  }, [token]);

  // Set timezone
  useEffect(() => {
    if (feature?.properties?.timezone) {
      moment.tz.setDefault(feature?.properties?.timezone);
    }
  }, [feature]);

  return (
    <>
      <Header params={match.params} />
      <Grid className={classes.root} container direction="column">
        {status && status.table && (
          <CurrentStatus summary={status?.table} metadata={status?.metadata} canDownload={false} tooltip={status?.tooltip} />
        )}
        <AppBar position="static">
          <Tabs
            className={classes.tabs}
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Monitoring" {...a11yProps(0)} />
            <Tab label="Weather" {...a11yProps(1)} />
            {performanceData && <Tab label="Performance" {...a11yProps(2)} />}
            <Tab label="Availability" {...a11yProps(3)} />
            <Tab label="Reporting" {...a11yProps(4)} />
          </Tabs>
        </AppBar>
        <TabPanel className={classes.tabPanel} value={value} index={PAGES.MONITORING}>
          <Monitoring data={status} />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value={value} index={PAGES.WEATHER}>
          <Weather />
        </TabPanel>
        {performanceData && (
          <TabPanel className={classes.tabPanel} value={value} index={PAGES.PERFORMANCEMPF5}>
            <Performance data={performanceData} />
          </TabPanel>
        )}
        <TabPanel className={classes.tabPanel} value={value} index={PAGES.AVAILABILITY}>
          <Availability feature={feature} />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value={value} index={PAGES.REPORTING}>
          <Reporting />
        </TabPanel>
      </Grid>
      <Footer />
    </>
  );
});

export default MainPage;
