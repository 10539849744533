import React, { useEffect, useState, useMemo, memo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import { AppBar, Grid, Tabs, Tab, Box } from '@material-ui/core';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { getAwsMetadata, getAwsTableInfo } from '../../lib/api';
import { useQuery } from '../../lib/hooks/useQuery';
import { getToken } from '../../lib/util';
import { useStyles } from './styles';
import TabPanel from '../../components/TabPanel';
import AwsData from '../awsData';
import CurrentStatus from '../../components/LocationLayout/CurrentStatus';

function a11yProps(key) {
  return {
    id: `station-tab-${key}`,
    'aria-controls': `station-tabpanel-${key}`,
    value: key
  };
}

const StationPage = memo(({ match }) => {
  const history = useHistory();
  const classes = useStyles();
  const query = useQuery();
  const tab = query.get('tab');
  const [tabName, setTabName] = useState(tab);
  const [awsTableInfo, setAwsTableInfo] = useState([]);
  const [metadata, setMetadata] = useState({});

  const { product } = useParams();
  const token = getToken();

  const found = awsTableInfo.find((r) => r.DataTable === tabName);
  const awsId = found?.AwsID;
  const dataTable = found?.DataTable;
  const timezone = found?.Timezone;
  const interval = parseInt(found?.Interval || '300', 10);

  const handleChange = (event, newValue) => {
    const params = new URLSearchParams();
    params.delete('tab');
    history.push({ search: params.toString() });
    setTabName(newValue);
  };

  const pages = useMemo(() => awsTableInfo.map((r) => r.DataTable), [awsTableInfo]);

  const fetchAwsTableInfo = async () => {
    try {
      const res = await getAwsTableInfo(product, token);

      if (!res.status || res.status === 200) {
        setAwsTableInfo(res.InfoRows);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAwsMetadata = async () => {
    try {
      const res = await getAwsMetadata(product, token);

      if (!res.status || res.status === 200) {
        setMetadata(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (pages.length) {
      setTabName(tab ?? pages[0]);
    }
  }, [pages, tab]);

  useEffect(() => {
    fetchAwsTableInfo();
    fetchAwsMetadata();
  }, []);

  useEffect(() => {
    if (timezone) {
      moment.tz.setDefault(timezone);
    }
  }, [timezone]);

  useEffect(() => {
    if (!token) {
      history.push('/');
    }
  }, [token]);

  return (
    <>
      <Header params={match.params} />
      <Grid className={classes.root} container direction="column">
        <AppBar position="static">
          <Tabs
            className={classes.tabs}
            value={tabName}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
          >
            {pages.map((p) => (
              <Tab key={p} label={p} {...a11yProps(p)} />
            ))}
          </Tabs>
        </AppBar>
        {pages.map((page) => (
          <TabPanel key={page} className={classes.tabPanel} value={tabName} index={page}>
            <Box height="100%" display="flex" flexDirection="column">
              <CurrentStatus summary={metadata?.table} tooltip={metadata?.tooltip} />
              <AwsData
                tabName={tabName}
                product={product}
                panel={metadata?.panel}
                awsId={awsId}
                token={token}
                dataTable={dataTable}
                interval={interval}
              />
            </Box>
          </TabPanel>
        ))}
      </Grid>
      <Footer />
    </>
  );
});

export default StationPage;
