/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Panel } from 'react-bootstrap';
import moment from 'moment-timezone';
import { Box } from '@material-ui/core';
import fileDownload from 'js-file-download';
import Preloader from './Preloader';
import CurrentStatusPopup from './LocationLayout/CurrentStatusPopup';
import Chart from './Chart/PopupChart';
import { getToken, getUser, getPassword } from '../lib/util';

import './Popup.css';
import { selectAwsInfo } from '../redux/slices/aws';

const Popup = ({ location, feature, selectFeature, loading }) => {
  const awsInfo = useSelector(selectAwsInfo);
  const [filename, setFilename] = useState(null);
  const [forecastData, setForecastData] = useState(null);

  const token = getToken();

  const downloadForecast = () => {
    if (filename) {
      let data = forecastData;
      if (filename.split('.')[1] !== 'csv') {
        data = JSON.stringify(forecastData);
      }

      fileDownload(data, filename);
    }
  };

  useEffect(() => {
    if (feature?.properties?.timezone) {
      moment.tz.setDefault(feature?.properties?.timezone);
    }
  }, [feature]);

  const popup = (location) => {
    const data = location;

    if (data.hoveredFeature) {
      return (
        <Panel>
          <Box padding={1}>
            {data.hoveredFeature.map((f, idx) => (
              <Box key={f.Name || idx} padding={1} onClick={() => selectFeature(f.Name)}>
                {f.DisplayName}
              </Box>
            ))}
          </Box>
        </Panel>
      );
    }

    if (!feature) {
      return null;
    }

    const { metadata, table, graph } = data;
    const { service: originalService, Name: product } = feature?.properties || {};
    const service = Array.isArray(originalService) ? originalService : [originalService];

    const isCFS = service.includes('CFS');
    const isAWS = service.includes('aws');
    const isMPF5 = service.includes('MPF5');
    const isOther = !isCFS && !isAWS && !isMPF5;

    const awsDataTables =
      isAWS && awsInfo[product] ? awsInfo[product]?.map((item) => item.DataTable) : [];

    return (
      <div>
        <Panel>
          <CurrentStatusPopup summary={table} metadata={metadata} />

          {graph && (
            <Chart {...graph} size="small" onDownloadForecast={filename && downloadForecast} />
          )}

          {isCFS ? (
            <div className="links-wrapper">
              <Panel.Footer>
                <Link to={`/main/${product}?tab=monitoring`}>Monitoring</Link>
              </Panel.Footer>
              <Panel.Footer>
                <Link to={`/main/${product}?tab=weather`}>Weather</Link>
              </Panel.Footer>
              <Panel.Footer>
                <Link to={`/main/${product}?tab=availability`}>Availability</Link>
              </Panel.Footer>
              <Panel.Footer>
                <Link to={`/main/${product}?tab=reporting`}>Reporting</Link>
              </Panel.Footer>
              {isAWS && (
                <Panel.Footer>
                  <Link to={`/main/${product}?tab=data`}>Data</Link>
                </Panel.Footer>
              )}
            </div>
          ) : isMPF5 ? (
            <div className="links-wrapper">
              <Panel.Footer>
                <Link to={`/main/${product}?tab=forecast`}>Forecast</Link>
              </Panel.Footer>
              <Panel.Footer>
                <Link to={`/main/${product}?tab=history`}>History</Link>
              </Panel.Footer>
              <Panel.Footer>
                <Link to={`/main/${product}?tab=performance_mpf5`}>Performance</Link>
              </Panel.Footer>
              <Panel.Footer>
                <Link to={`/main/${product}?tab=weather`}>Weather</Link>
              </Panel.Footer>
              <Panel.Footer>
                <Link to={`/main/${product}?tab=reporting`}>Reporting</Link>
              </Panel.Footer>
              {isAWS && (
                <Panel.Footer>
                  <Link to={`/main/${product}?tab=data`}>Data</Link>
                </Panel.Footer>
              )}
            </div>
          ) : isAWS ? (
            <div className="links-wrapper">
              {awsDataTables.map((dt) => (
                <Panel.Footer>
                  <Link to={`/main/${product}?tab=${dt}`}>{dt}</Link>
                </Panel.Footer>
              ))}
            </div>
          ) : isOther ? (
            <div className="links-wrapper">
              <Panel.Footer>
                <Link to={`/main/${product}?tab=forecast`}>Forecast</Link>
              </Panel.Footer>
              <Panel.Footer>
                <Link to={`/main/${product}?tab=history`}>History</Link>
              </Panel.Footer>
              <Panel.Footer>
                <Link to={`/main/${product}?tab=weather`}>Weather</Link>
              </Panel.Footer>
              <Panel.Footer>
                <Link to={`/main/${product}?tab=reporting`}>Reporting</Link>
              </Panel.Footer>
            </div>
          ) : null}
        </Panel>
      </div>
    );
  };

  const fetchForecast = async () => {
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/GetForecast/${token}/${feature?.properties?.Name}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Username: getUser(),
          Password: getPassword()
        }
      }
    );
    const file = res.headers.get('content-disposition')?.split(';')[1];
    const forecastfile = file?.split('=')[1];

    if (forecastfile) {
      setFilename(forecastfile);
      setForecastData(await res.text());
    } else {
      setFilename(null);
    }
  };

  useEffect(() => {
    if (feature?.properties?.Name) {
      fetchForecast();
    }
  }, [feature?.properties?.Name]);

  if (loading) {
    return (
      <Panel>
        <Panel.Heading />
        <Preloader />
        <Panel.Footer />
      </Panel>
    );
  }

  if (location) {
    return popup(location);
  } else {
    return null;
  }
};

export default Popup;
