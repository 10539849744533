import React, { useRef } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography
} from '@material-ui/core';
import {
  getPlotDatasets,
  getPlotGraphOptions,
  getBarDatasets,
  getBarGraphOptions
} from '../../lib/util';

const useStyles = makeStyles((theme) => ({
  dialog: {
    // width: 1200,
    // height: 1000
  },
  dialogContent: {
    // width: 1200,
    height: 1000
  },
  chartWrapper: {
    height: '100%',
    flexDirection: 'column',
    flexWrap: 'nowrap'
  },
  fullHeight: {
    flex: 1
  }
}));

const FullChartModal = ({ open, data, onClose }) => {
  const classes = useStyles();
  const chartRef = useRef(null);

  if (!data) {
    return null;
  }

  const { title, axis, type } = data;
  const graphData = getPlotDatasets(data);
  const graphOptions = getPlotGraphOptions(axis);

  const resetZoom = () => {
    chartRef.current.resetZoom();
  };

  return (
    <Dialog className={classes.dialog} open={open} onClose={onClose} fullWidth maxWidth="xl">
      <DialogContent className={classes.dialogContent}>
        <Grid container className={classes.chartWrapper}>
          <Grid item>
            <Box p={2}>
              <Typography align="center" variant="h5">
                {title}
              </Typography>
            </Box>
            <Box position="absolute" top={12} right={12} display="flex" alignItems="center">
              <Button
                className={classes.resetBtn}
                size="small"
                variant="outlined"
                color="primary"
                onClick={resetZoom}
              >
                Reset zoom
              </Button>
            </Box>
          </Grid>
          <Grid item className={classes.fullHeight}>
            <div id="full-graph" style={{ position: 'relative', width: '100%', height: '100%' }}>
              {type === 'barchart' && (
                <Bar
                  ref={chartRef}
                  data={getBarDatasets(data)}
                  options={getBarGraphOptions(axis)}
                />
              )}
              {type === 'timeseries' && (
                <Line
                  ref={chartRef}
                  data={graphData}
                  options={graphOptions}
                  datasetKeyProvider={() => Math.random()}
                />
              )}
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box p={2}>
          <Button variant="contained" color="primary" onClick={onClose}>
            CLOSE
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default FullChartModal;
