import React, { useMemo } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { Panel } from 'react-bootstrap';
import { GetAppRounded } from '@material-ui/icons';
import '../LocationLayout/Scatter.css';
import Preloader from '../Preloader';
import {
  getPlotDatasets,
  getPlotGraphOptions,
  getBarDatasets,
  getBarGraphOptions
} from '../../lib/util';

const Chart = ({ title, series, axis, type, size, onDownloadForecast }) => {
  const isSmall = size === 'small';

  const graph = useMemo(() => {
    if (type === 'barchart') {
      const data = getBarDatasets({ series, type });
      const options = getBarGraphOptions();

      return <Bar data={data} options={options} />;
    }

    if (type === 'timeseries') {
      const data = getPlotDatasets({ series, axis }, true);
      const options = getPlotGraphOptions(axis, true, isSmall);
      return <Line data={data} options={options} datasetKeyProvider={() => Math.random()} />;
    }

    return null;
  }, [series, axis, type]);

  if (series) {
    return (
      <div>
        <Panel>
          <Panel.Heading>
            <Panel.Title
              componentClass="h2"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                fontSize: isSmall ? '12px' : '16px'
              }}
            >
              {title}
              {onDownloadForecast && (
                <div
                  onClick={onDownloadForecast}
                  style={{
                    cursor: 'pointer'
                  }}
                >
                  <GetAppRounded />
                </div>
              )}
            </Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <div id="mini-graph" style={{ height: isSmall ? '200px' : '400px' }}>
              {graph}
            </div>
          </Panel.Body>
        </Panel>
      </div>
    );
  } else {
    return <Preloader />;
  }
};

export default Chart;
