/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TableSortLabel
} from '@material-ui/core';
import moment from 'moment';
import { Menu as MenuIcon } from '@material-ui/icons';
import { useStyles } from './styles';
import { findVariableLongName } from '../../lib/util';
import ProaMenu from '../../components/menu';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const AwsDataTable = ({
  data = [],
  height,
  variableOptions,
  downloadCsv,
  downloadAllInCsv,
  setFormat,
  setHidden
}) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('timestamp');

  const hideDisplay = () => setHidden(true);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formatCell = (key, value) => {
    if (key === 'timestamp') {
      return moment(new Date(value * 1000)).format('DD/MM/yyyy HH:mm:ss Z');
    }
    return value;
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  if (!data?.length)
    return (
      <Box py={4} px={2}>
        No items found. Please try different filters.
      </Box>
    );
  const columns = Object.keys(data[0]).filter((item) => item !== 'timestamp');
  columns.unshift('timestamp');

  const menuItems = [
    {
      label: 'Download CSV',
      onClick: downloadCsv
    },
    {
      label: 'Download All Variables',
      onClick: downloadAllInCsv
    },
    {
      label: 'Remove display',
      onClick: hideDisplay
    }
  ];

  const containerStyle = height > 0 ? { height, overflow: 'auto' } : {};

  return (
    <Box style={containerStyle}>
      <Box
        textAlign="right"
        bgcolor="#eee"
        style={{
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8
        }}
      >
        <ProaMenu label={<MenuIcon />} items={menuItems} />
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              {columns.map((key, index) => (
                <TableCell
                  align="center"
                  key={`tablehead-${key}-${index}`}
                  className={classes.tableCell}
                  sortDirection={orderBy === key ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === key}
                    direction={orderBy === key ? order : 'asc'}
                    onClick={() => handleRequestSort(key)}
                  >
                    {findVariableLongName(variableOptions, key)}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(data, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow key={row.timestamp || index}>
                  {columns.map((k) => (
                    <TableCell
                      key={`tablecell-${k}-${index}`}
                      align="center"
                      className={classes.tableCell}
                    >
                      {formatCell(k, row[k])}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100, 250]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default AwsDataTable;
